import {
  Redeem,
  Receipt,
  Home,
  People,
  Description,
  Storage,
  Terminal,
} from "@mui/icons-material";
export default {
  title: "Payout",
  name: "payout",
  icon: "payout",
  segments: [
    {
      type: "link",
      name: "clients",
      icon: <People />,
      title: "Clients",
      link: "/platform/payout/clients",
    },
    {
      name: "Manage",
      type: "segment",
      children: [
        {
          type: "link",
          name: "payouts",
          title: "Payouts",
          link: "/platform/payout/payouts",
          icon: <Receipt />,
        },
        {
          type: "link",
          name: "payoutmethods",
          title: "Payout Methods",
          link: "/platform/payout/methods",
          icon: <Redeem />,
        },
      ],
    },
  ],
};
