import { useEffect, createContext, useState, useContext, useMemo } from "react";

import { useSelector } from "react-redux";
import { Socket } from "./useSocket";
import Player from "../models/Player";
import { jwtData } from "@utils/jwt";
const useMultiplayer = () => {
  const token = useSelector((state) => state.token);
  const tokenData = jwtData(token);
  const { socket } = useContext(Socket);

  const [players, setPlayers] = useState([]);
  const [player, setPlayer] = useState(null);

  const otherPlayers = useMemo(() => {
    return [...players].filter((player) => player?.token !== token);
  }, [players, token]);

  const addPlayer = (value) => {
    setPlayers((players) => [
      ...players,
      new Player(value.id, value.position, value.token, value.avatar),
    ]);
  };

  const setMovement = (position, rotation) => {
    if (player) {
      player.setPositionFromMovement(position, rotation);
      setPlayer(player);

      socket.emit("movement", player.position);
    }
  };

  const join = () => {
    let me = new Player(socket.id, null, token, tokenData.avatar);

    setPlayer((prev) => me);
    socket.emit("join", me);
  };

  const joined = (newPlayers) => {
    setPlayers((prev) => {
      return newPlayers.map((value) => {
        return new Player(value.id, value.position, value.token, value.avatar);
      });
    });
  };

  const playerLeave = (data) => {
    setPlayers((players) => {
      return players.filter((player) => player?.id !== data);
    });
  };
  const playerMovement = (data) => {
    setPlayers((prev) => {
      return prev.map((player) => {
        if (player?.id === data.id) {
          player.setPosition(data.position);
        }
        return player;
      });
    });
  };

  return {
    players,
    addPlayer,
    playerMovement,
    playerLeave,
    joined,
    join,
    otherPlayers,
    setMovement,
  };
};

export default useMultiplayer;
