import dirt from "./blocks/dirt.png";
import diamond from "./blocks/diamond_block.png";
import grass from "./blocks/grass.png";
import tile from "./blocks/tile.png";
const images = {
  dirt,
  diamond,
  grass,
  tile,
};
export default images;
