import * as api from "@lib/api";

const get = (platform, endpoint, data = [], options = {}) => {
  return toAdmin("get", platform, endpoint, data, options);
};
const post = (platform, endpoint, data = [], options = {}) => {
  return toAdmin("post", platform, endpoint, data, options);
};

const put = (platform, endpoint, data = [], options = {}) => {
  return toAdmin("put", platform, endpoint, data, options);
};

const dlt = (platform, endpoint, data = [], options = {}) => {
  return toAdmin("delete", platform, endpoint, data, options);
};
const toAdmin = (
  method = "get",
  platform,
  endpoint,
  data = [],
  options = {}
) => {
  let send_data = {
    platform,
    method,
    endpoint,
    data,
  };
  if (options?.headers != undefined) {
    if (options.headers["Content-Type"] == "multipart/form-data") {
      send_data = new FormData();

      send_data.append("platform", platform);
      send_data.append("method", method);
      send_data.append("endpoint", endpoint);
      for (let key in data) {
        if (data[key] instanceof File) {
          send_data.append(key, data[key]);
        } else {
          send_data.append(`data[${key}]`, data[key]);
        }
      }
    }
  }

  return api.post("toadmin", send_data, options);
};

export default { get, post, put, dlt };
