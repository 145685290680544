import DashboardPage from "../pages/DashboardPage";
import MethodsPage from "../pages/MethodsPage";
import SpecificMethodPage from "../pages/SpecificMethodPage";
import AddMethodPage from "../pages/AddMethodPage";
import ClientsPage from "../pages/ClientsPage";
import DocsPage from "@components/APIDocs";
import APIEditor from "@components/APIDocs/APIEditor";
import ManagerPage from "@features/platforms/components/DatabaseManager";

import PlatformPage from "@features/platforms/layout/PlatformPage";
const docsFile = require("./../api/docs.json");

const basePath = "/platform/:platform";
const routes = [
  {
    path: "/clients",
    element: <ClientsPage />,
    exact: true,
  },
  {
    path: "/methods",
    element: <MethodsPage />,
    exact: true,
  },
  {
    path: "/methods/add",
    element: <AddMethodPage />,
    exact: true,
  },
  {
    path: "/methods/:method_id",
    element: <SpecificMethodPage />,
    exact: true,
  },
].map((route) => ({
  ...route,
  path: basePath + route.path,
  element: <PlatformPage>{(_) => route.element}</PlatformPage>,
}));

export default routes;
