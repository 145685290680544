import {
  Container,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Avatar,
  Box,
  Tooltip,
} from "@mui/material";
import UserAvatar from "@components/UserAvatar";
import PageLayout from "./../../layout/PageLayout";
import { useQuery } from "@tanstack/react-query";
import { getUsers } from "@api/user";
import { roleIcons } from "../../components/Roles";
const UsersPage = () => {
  const { data: users, isLoading: usersLoading } = useQuery(["users"], () =>
    getUsers()
  );

  return (
    <PageLayout>
      <Container maxWidth="xl">
        <Typography variant="h5">Users</Typography>
        {usersLoading ? "Loading..." : <UserTable users={users} />}
      </Container>
    </PageLayout>
  );
};

const UserTable = (props) => {
  const { users } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Role</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user) => {
          let role = roleIcons[user.role];
          return (
            <TableRow>
              <TableCell>
                <Box display="flex" alignItems="center">
                  <UserAvatar name={user.first_name + " " + user.last_name} />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {user.first_name} {user.last_name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <Tooltip title={role.name}>
                  <Avatar>{role.Icon}</Avatar>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default UsersPage;
