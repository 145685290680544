import APIDocsPage from "@components/APIDocs";

import { useQuery } from "@tanstack/react-query";
import { getPlatforms } from "../../api";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";
const ApiDocumentation = ({ platform }) => {
  const { data } = useQuery(["platforms"], getPlatforms, {
    refetchOnWindowFocus: false,
  });

  const navigate = useNavigate();

  const platformData = useMemo(() => {
    if (data) {
      return data.find((p) => p.platform_name === platform);
    }
  }, [data, platform]);

  if (!data || !platformData) {
    return null;
  }

  if (platformData.api_docs_url == "") {
    return (
      <Container>
        <Typography>No documentation</Typography>
      </Container>
    );
  }
  return (
    <>
      <Button
        onClick={() => {
          navigate("edit");
        }}
      >
        Editor
      </Button>
      <APIDocsPage file={platformData.api_docs_url} />
    </>
  );
};

export default ApiDocumentation;
