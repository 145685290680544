const httpbuildquery = (data) => {
  let query = "";
  let c = 0;
  for (let key in data) {
    if (typeof data[key] == "object") {
      let subquery = "";
      data[key].map((value, index) => {
        subquery += key + "[" + index + "]=" + encodeURIComponent(value);
        if (index != data[key].length - 1) subquery += "&";
      });
      query += subquery;
    } else {
      query += key + "=" + encodeURIComponent(data[key]);
    }
    if (c != Object.keys(data).length - 1) query += "&";
    c += 1;
  }
  return query;
};

export default httpbuildquery;
