import React, { useRef, useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Socket } from "../../hooks/useSocket";
import { MultiPlayerContext } from "../../providers";
import Peer from "simple-peer";
import { jwtData } from "@utils/jwt";
import io from "socket.io-client";

const videoConstraints = {
  height: window.innerHeight / 2,
  width: window.innerWidth / 2,
};

const Audio = (props) => {
  const { peer } = props;
  const ref = useRef();
  useEffect(() => {
    peer?.on("stream", (stream) => {
      ref.current.srcObject = stream;
    });
  }, []);

  return <audio ref={ref} autoPlay playsInline />;
};
const Video = (props) => {
  const { peer } = props;
  const ref = useRef();
  useEffect(() => {
    peer?.on("stream", (stream) => {
      ref.current.srcObject = stream;
    });
  }, []);

  return (
    <video style={{ height: 200, width: 250 }} playsInline autoPlay ref={ref} />
  );
};

const VideoChat = (props) => {
  const [peers, setPeers] = useState([]);
  const socketRef = useRef();
  const userVideo = useRef();
  const peersRef = useRef([]);
  const roomID = "1";

  useEffect(() => {
    socketRef.current = io.connect("https://api.master.yflow.io:12050");
    navigator.mediaDevices
      .getUserMedia({ video: videoConstraints, audio: true })
      .then((stream) => {
        userVideo.current.srcObject = stream;
        socketRef.current.emit("join room", roomID);
        socketRef.current.on("all users", (users) => {
          const peers = [];
          users.forEach((userID) => {
            const peer = createPeer(userID, socketRef.current.id, stream);
            peersRef.current.push({
              peerID: userID,
              peer,
            });
            peers.push(peer);
          });
          setPeers(peers);
        });

        socketRef.current.on("user joined", (payload) => {
          const peer = addPeer(payload.signal, payload.callerID, stream);
          peersRef.current.push({
            peerID: payload.callerID,
            peer,
          });

          setPeers((users) => [...users, peer]);
        });

        socketRef.current.on("receiving returned signal", (payload) => {
          const item = peersRef.current.find((p) => p.peerID === payload.id);
          item.peer.signal(payload.signal);
        });
      });
  }, []);

  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer?.on("signal", (signal) => {
      socketRef.current.emit("sending signal", {
        userToSignal,
        callerID,
        signal,
      });
    });

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });

    peer?.on("signal", (signal) => {
      socketRef.current.emit("returning signal", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  return (
    <>
      <audio muted ref={userVideo} autoPlay playsInline />
      {peers.map((peer, index) => {
        return <Audio key={index} peer={peer} />;
      })}
    </>
  );
};

export default VideoChat;
