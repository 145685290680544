import React, { Suspense } from "react";
import { useLoader } from "@react-three/fiber";
import { usePlane } from "@react-three/cannon";

import { Plane, useTexture } from "@react-three/drei";
import { tileTexture } from "../images/textures";
import * as THREE from "three";
export const Terrain = () => {
  const [ref] = usePlane(() => ({
    rotation: [-Math.PI / 2, 0, 0],
    position: [0, -5, 0],
  }));

  tileTexture.wrapS = THREE.RepeatWrapping;
  tileTexture.wrapT = THREE.RepeatWrapping;
  tileTexture.repeat.set(100, 100);

  return (
    <mesh ref={ref}>
      <planeBufferGeometry attach="geometry" args={[64, 64]} />
      <meshStandardMaterial attach="material" map={tileTexture} />
    </mesh>
  );
};
