import DashboardPage from "../pages/DashboardPage";
import ProfilingDashboard from "../pages/Profiling/Dashboard";
import ProfilingEdit from "../pages/Profiling/Edit";
import ProfilingQuestion from "../pages/Profiling/Question";
import StatsPage from "../pages/Stats";
import DocsPage from "@components/APIDocs";
import APIEditor from "@components/APIDocs/APIEditor";
import ManagerPage from "@features/platforms/components/DatabaseManager";

import PlatformPage from "@features/platforms/layout/PlatformPage";

const basePath = "/platform/:platform";
const routes = [
  {
    path: "/stats",
    element: <StatsPage />,
    exact: true,
  },
  {
    path: "/profiling",
    element: <ProfilingDashboard />,
    exact: true,
  },
  {
    path: "/profiling/:country",
    element: <ProfilingEdit />,
    exact: true,
  },
  {
    path: "/profiling/:country/:profiler_id",
    element: <ProfilingQuestion />,
    exact: true,
  },
].map((route) => ({
  ...route,
  path: basePath + route.path,
  element: <PlatformPage>{(_) => route.element}</PlatformPage>,
}));

export default routes;
