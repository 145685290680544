import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";

import { routes as communicationRoutes } from "./../features/Communication";
import { routes as payoutRoutes } from "./../features/Payout";
import { routes as surveyRoutes } from "./../features/Survey";

import { useQuery } from "@tanstack/react-query";
import PlatformPage from "../layout/PlatformPage";
import ApiDocumentation from "@features/platforms/components/ApiDocumentation";
import ApiDocumentationEditor from "@features/platforms/components/ApiDocumentation/Editor";
import DatabaseManager from "@features/platforms/components/DatabaseManager";

const shared = [
  {
    path: "/platform/:platform",
    element: <PlatformPage>{(platform) => <h2>hej</h2>}</PlatformPage>,
  },
  {
    path: "/platform/:platform/documentation",
    element: (
      <PlatformPage>
        {(platform) => <ApiDocumentation platform={platform} />}
      </PlatformPage>
    ),
  },
  {
    path: "/platform/:platform/documentation/edit",
    element: (
      <PlatformPage showSideNavs={false}>
        {(platform) => <ApiDocumentationEditor platform={platform} />}
      </PlatformPage>
    ),
  },
  {
    path: "/platform/:platform/db",
    element: (
      <PlatformPage>
        {(platform) => <DatabaseManager database={platform} />}
      </PlatformPage>
    ),
  },
];

const routes = [
  ...communicationRoutes,
  ...payoutRoutes,
  ...surveyRoutes,
  ...shared,
];

export default routes;
