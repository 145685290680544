import "./style/index.scss";
import { useQuery } from "@tanstack/react-query";
import { getPlatforms } from "../../api";

import PlatformCard from "../PlatformCard";
import { Grid } from "@mui/material";
const PlatformList = (props) => {
  const { onPlatformClick = () => {} } = props;
  const { data, isLoading, error } = useQuery(["platforms"], getPlatforms, {
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <Grid container spacing={2}>
      {data?.map((platform) => (
        <Grid item xs={5} md={3} lg={3}>
          <PlatformCard platform={platform} onClick={onPlatformClick} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PlatformList;
