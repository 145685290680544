import { useForm } from "@components/Form";
import React from "react";

import {
  Typography,
  Box,
  Grid,
  TextField,
  Card,
  Select,
  MenuItem,
  Button,
  CardContent,
  InputLabel,
  FormControl,
  Container,
} from "@mui/material";

const Form = () => {
  const name = "method_item";
  const { method, update, isValid } = useForm(
    {
      title: "required|string|min_length[5]",
      description: "required|string",
      item_price: "required|string",
      currency: "required|string",
      minimum_amount: "required|min_amount[10]",
    },
    null
  );

  const currencies = [
    {
      iso: "USD",
    },
    { iso: "SEK" },
  ];

  return (
    <Container maxWidth="sm">
      <Box rowGap={2} display="flex" flexDirection="column" minWidth={500}>
        <Box rowGap={2} display="flex" flexDirection="column">
          <Typography variant="h5">Info</Typography>
          {JSON.stringify(method)}
          {JSON.stringify(isValid)}

          <input type="hidden" name={name} value={JSON.stringify(method)} />
          <TextField
            fullWidth
            label="Title"
            name="title"
            value={method?.title}
            onChange={update}
            error={!isValid.title}
          />

          <TextField
            fullWidth
            label="Description"
            value={method?.description}
            name="description"
            multiline
            rows={4}
            onChange={update}
            error={!isValid.description}
          />
        </Box>

        <Box rowGap={2} display="flex" flexDirection="column">
          <Typography variant="h5">Price</Typography>

          <TextField
            fullWidth
            label="Item price"
            value={method?.item_price}
            name="item_price"
            onChange={update}
            error={!isValid.item_price}
          />
          <FormControl>
            <InputLabel id="currencylabel">Currency</InputLabel>
            <Select
              fullWidth
              labelId="currencylabel"
              label="Currency"
              value={method?.currency}
              onChange={update}
              name="currency"
              id="currencyselect"
              error={!isValid.currency}
            >
              {currencies?.map((currency) => (
                <MenuItem value={currency.iso}>{currency.iso}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Minimum Amount"
            value={method?.minimum_amount}
            name="minimum_amount"
            onChange={update}
            error={!isValid.minimum_amount}
          />
          <Typography variant="caption">
            Minimum price:{" "}
            <u>
              <strong>
                {method?.minimum_amount * method?.item_price} {method?.currency}
              </strong>
            </u>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Form;
