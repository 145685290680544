import React from "react";
import * as constants from "@constants";
import {
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar } from "./AppBar";
import { NavLink, useNavigate } from "react-router-dom";
import UserAvatar from "@components/UserAvatar";

import store, { actions } from "@store";
import { useSelector } from "react-redux";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { Logout, Person, Settings, Groups, Public } from "@mui/icons-material";

import { getUserData } from "@api/user";
const TopNavigation = (props) => {
  const { pages = [], sidebarOpen, setOpenSidebar = () => {} } = props;

  const navigate = useNavigate();
  return (
    <AppBar position="fixed" color="background" open={sidebarOpen}>
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <div style={{ flex: 0, alignItems: "center", display: "flex" }}>
            <Typography variant="h6">YFlow</Typography>
          </div>
        </div>
        {pages.map((page, index) => {
          return (
            <NavLink to={page.path}>
              <Button color="inherit" key={index}>
                {page.title}
              </Button>
            </NavLink>
          );
        })}

        <AvatarMenu />
      </Toolbar>
    </AppBar>
  );
};

export default TopNavigation;
const AvatarMenu = (props) => {
  const navigate = useNavigate();

  const { data: user, isLoading, error } = useQuery(["user"], getUserData);

  const settings = [
    {
      title: "Profile",
      link: "/settings/profile",
      icon: <Person />,
    },
    {
      title: "Settings",
      link: "/settings",
      icon: <Settings />,
    },
    {
      title: "Logout",
      onClick: () => {
        store.dispatch(actions.logout());
      },
      icon: <Logout />,
    },
  ];

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleMenuClick = (item) => {
    if (item.onClick) {
      item.onClick();
    } else if (item.link) {
      navigate(item.link);
    }
    setAnchorElUser(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <UserAvatar name={user?.first_name + " " + user?.last_name} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting, index) => (
          <MenuItem key={index} onClick={() => handleMenuClick(setting)}>
            {setting.icon}
            <Typography textAlign="center">{setting.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
