import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useParams,
} from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { getPlatforms } from "../../api";
import PageLayout from "../PageLayout";
import { useMemo } from "react";
import sideBarItems from "./sidebarItems";
const PlatformPage = (props) => {
  const { children, showSideNavs = true } = props;
  const { platform } = useParams();

  const platforms = useQuery(["platforms"], getPlatforms, {
    refetchOnWindowFocus: false,
  });

  const platformData = useMemo(() => {
    if (platforms.data) {
      return platforms.data.find((p) => p.platform_name === platform);
    }
  }, [platforms.data, platform]);

  if (platforms.data) {
    if (!platformData) {
      return <Navigate to="/home" />;
    }
  }
  if (!platformData) {
    return null;
  }

  return (
    <PageLayout
      sideBarItems={{
        title: platformData.platform_name,
        name: platformData.platform_name,
        icon: platformData.platform_name,
        segments: sideBarItems(platform),
      }}
      showSideNavs={showSideNavs}
    >
      {children(platform)}
    </PageLayout>
  );
};

export default PlatformPage;
