import {
  Redeem,
  Receipt,
  Home,
  People,
  Description,
  Dangerous,
  Person,
} from "@mui/icons-material";
export default {
  title: "Settings",
  name: "settings",
  segments: [
    {
      type: "link",
      name: "profile",
      icon: <Person />,
      title: "Profile",
      link: "/settings/profile",
    },
    {
      type: "link",
      name: "users",
      icon: <People />,
      title: "Users",
      link: "/settings/users",
    },

    {
      name: "Very important stuff",
      type: "segment",
      children: [
        {
          type: "link",
          name: "dontclick",
          title: "Don't go here",
          link: "/settings/dontclick",
          icon: <Dangerous />,
        },
      ],
    },
  ],
};
