import "./style/index.scss";
import { Container, Box, Toolbar } from "@mui/material";
import { createContext } from "react";
import SideBar from "@components/layout/SideBar";
import React, { Fragment } from "react";

const PageLayout = (props) => {
  const {
    children,
    sideBarItems = [],
    active = null,
    showSideNavs = true,
  } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      {showSideNavs && <SideBar items={sideBarItems} active={active} />}

      <Box
        sx={{
          bgcolor: "white.main",
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default PageLayout;
