import { useEffect, createContext, useState, useContext, useMemo } from "react";

import useMultiplayer from "../hooks/useMultiplayer";

export const MultiPlayerContext = createContext();

export const MultiPlayerProvider = ({ children }) => {
  const multiplayer = useMultiplayer();

  return (
    <MultiPlayerContext.Provider value={multiplayer}>
      {children}
    </MultiPlayerContext.Provider>
  );
};
