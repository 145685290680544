import React, { Fragment, useEffect, useMemo, useReducer } from "react";

import {
  Typography,
  Box,
  Grid,
  TextField,
  Card,
  Select,
  MenuItem,
  Button,
  CardContent,
  InputLabel,
  FormControl,
} from "@mui/material";
import { getCurrencies } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "@components/Form";

export const formSkeleton = {
  title: "required|string|min_length[5]",
  description: "required|string",
  item_price: "required",
  currency: "required|string",
  minimum_amount: "required|min_amount[10]",
};

const MethodItemForm = (props) => {
  const {
    methodItem = null,
    onSubmit,
    name = "method_item",
    update: updateParent = () => {},
    setValid = () => {},
  } = props;

  const {
    data: currencies,
    isLoading,
    error,
  } = useQuery(["currencies"], getCurrencies);

  const form = useForm(formSkeleton, methodItem);

  useEffect(() => {
    if (methodItem != null) {
      form.setInitial(methodItem);
    }
  }, [methodItem]);

  useEffect(() => {
    updateParent({
      target: {
        name,
        value: form.form,
      },
    });
  }, [form.form]);

  useEffect(() => {
    setValid(form.isValid.all);
  }, [form.isValid]);

  return (
    <Box rowGap={2} display="flex" flexDirection="column" minWidth={500}>
      <Box rowGap={2} display="flex" flexDirection="column">
        <Typography variant="h5">Info</Typography>
        <input type="hidden" name={name} value={JSON.stringify(form.form)} />

        <TextField fullWidth label="Title" {...form.getProps("title")} />

        <TextField
          fullWidth
          label="Description"
          multiline
          rows={4}
          {...form.getProps("description")}
        />
      </Box>

      <Box rowGap={2} display="flex" flexDirection="column">
        <Typography variant="h5">Price</Typography>

        <TextField
          fullWidth
          label="Item price"
          {...form.getProps("item_price")}
        />
        <FormControl>
          <InputLabel id="currencylabel">Currency</InputLabel>
          <Select
            fullWidth
            labelId="currencylabel"
            label="Currency"
            {...form.getProps("currency")}
            id="currencyselect"
          >
            <MenuItem value="">-</MenuItem>
            {currencies?.map((currency) => (
              <MenuItem value={currency.iso}>{currency.iso}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Minimum Amount"
          {...form.getProps("minimum_amount")}
        />
        <Typography variant="caption">
          Minimum price:{" "}
          <u>
            <strong>
              {form.form?.minimum_amount * form.form?.item_price}{" "}
              {form.form?.currency}
            </strong>
          </u>
        </Typography>
      </Box>

      <Box>
        <Typography variant="h5">Recipient Type</Typography>
        <Card variant="outlined">
          <CardContent>
            {methodItem?.recipient_type?.map((item, index) => {
              return (
                <Box>
                  <h4 style={{ margin: 0 }}>{item.name}</h4>
                  {item?.items?.map((item, index) => {
                    return (
                      <Box>
                        <Typography>{item.title}</Typography>
                        <Typography>{item.name}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default MethodItemForm;
