const MuiButton = {
  styleOverrides: {
    // Name of the slot
    root: (props) => MuiButtonStyle(props),
  },
};

export default MuiButton;

const MuiButtonStyle = (props) => {
  const { ownerState } = props;

  const base = {
    textTransform: "none",
    boxShadow: "none",
  };
  switch (ownerState.variant) {
    case "contained":
      return {
        ...base,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          boxShadow: "none",
          transform: "scale(1.01)",
        },
        "&:active": {
          boxShadow: "none",
        },
        "&.focused": {
          boxShadow: "none",
        },
      };
    case "outlined":
      return {
        ...base,
        borderWidth: 2,
        "&:hover": {
          borderWidth: 2,
        },
      };
    default:
      return {
        ...base,
      };
  }
};
