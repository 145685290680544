import Form from "@components/Form/__tests__/form";

let routes = [
  {
    path: "/tests/form",
    element: <Form />,
    exact: true,
  },
];

export default routes;
