import "./style/index.scss";
import React, { Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  DialogActions,
  Button,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Flag from "react-world-flags";
import countryList from "react-select-country-list";

const CountryList = (props) => {
  const { onClose, open, onSubmit, notAllowedCountries = [] } = props;

  const countriesInitial = React.useMemo(() => {
    let temp = countryList()
      .getData()
      .filter((item) => {
        return !notAllowedCountries.includes(item.value);
      });
    return temp.map((country) => ({
      ...country,
      flag: <Flag code={country.value} height="16" />,
      show: true,
    }));
  }, []);
  const [countries, setCountries] = React.useState(countriesInitial);
  const [selected, setSelected] = React.useState([]);
  const handleClose = () => {
    onClose();
  };

  React.useEffect(() => {
    if (open) {
      setSelected([]);
      setCountries(countriesInitial);
    }
  }, [open]);

  const searchCountry = (e) => {
    let value = e.target.value;
    let temp = countriesInitial.map((country) => {
      if (country.label.toLowerCase().includes(value.toLowerCase())) {
        return { ...country, show: true };
      } else {
        return { ...country, show: false };
      }
    });
    setCountries(temp);
  };
  const deselectAll = () => {
    setSelected([]);
  };
  const selectAll = () => {
    setSelected(countries.map((country) => country.value));
  };
  const selectCountry = (country) => {
    let index = selected.find((item) => item === country.value);
    if (index) {
      setSelected(selected.filter((item) => item !== country.value));
    } else {
      setSelected([...selected, country.value]);
    }
  };

  const submit = () => {
    onSubmit(selected);
    handleClose();
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select country</DialogTitle>
      <Box fullWidth sx={{ p: 1 }}>
        <TextField
          fullWidth
          label="Search"
          size="small"
          onKeyUp={searchCountry}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Box>
      <List sx={{ pt: 0, overflow: "scroll" }}>
        {countries.map((country, index) => {
          let isSelected =
            selected.find((item) => item === country.value) != null;
          if (country.show === false) {
            return null;
          }
          return (
            <ListItem
              button
              onClick={() => {
                selectCountry(country);
              }}
              key={String(index)}
              selected={isSelected}
            >
              <ListItemAvatar>{country.flag}</ListItemAvatar>
              <ListItemText primary={country.label} />
            </ListItem>
          );
        })}
      </List>
      <DialogActions>
        <Button onClick={deselectAll}>Deselect all</Button>
        <Button onClick={selectAll}>Select All</Button>
        <div
          style={{ flexGrow: 1, justifyContent: "flex-end", display: "flex" }}
        >
          <Button
            variant="contained"
            disabled={selected.length == 0}
            onClick={submit}
          >
            Submit ({selected.length})
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CountryList;
