const rules = {
  string: (value) => {
    return typeof value == "string";
  },
  integer: (value) => {
    let int = value * 1;
    return String(int) != "NaN" && value != "";
  },
  min_amount: (value, amount) => {
    return rules.integer(value) && value * 1 >= amount * 1;
  },
  required: (value) => {
    return value != "";
  },
  min_length: (value, amount) => {
    if (value == undefined) return false;
    return value.length >= amount;
  },
  valid_email: (value) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  },
};
export default rules;
