import React from "react";
import { isAllTrue } from "@utils/array";
import rules from "./../utils/rules";

const formReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    case "set":
      return action.payload;

    default:
      return state;
  }
};

const useForm = (skeleton, initial, extra = {}) => {
  const getInitial = (value = null) => {
    let ini = value != null ? value : initial == null ? {} : initial;
    const emptySkeleton = {};
    Object.keys(skeleton).forEach((value) => {
      emptySkeleton[value] = ini[value] == null ? "" : ini[value];
    });

    return {
      ...emptySkeleton,
    };
  };
  React.useEffect(() => {
    dispatch({ type: "set", payload: getInitial() });
  }, [initial]);
  const setInitial = (value) => {
    dispatch({ type: "set", payload: getInitial(value) });
  };

  const [form, dispatch] = React.useReducer(formReducer, getInitial());
  const update = (e) => {
    dispatch({
      type: "update",
      payload: {
        [e.target.name]: e.target.value,
      },
    });
  };

  const isValidRecursive = (obj, skel = null, valid = {}) => {
    if (skel == null) skel = skeleton;

    Object.keys(skel).forEach((key) => {
      if (typeof skel[key] == "object") {
        valid[key] = isValidRecursive(obj[key], skel[key], (valid[key] = {}));
        valid[key]["all"] = isAllTrue(Object.values(valid[key]));
      } else {
        valid[key] = isValidParameter(obj[key], skel[key]);
      }
    });
    return valid;
  };

  const isValid = React.useMemo(() => {
    let valid = isValidRecursive(form);

    return { all: isAllTrue(Object.values(valid)), ...valid };
  }, [form]);

  const getProps = (name) => {
    return {
      name,
      value: form[name],
      onChange: update,
      error: !isValid[name],
    };
  };

  return { form, update, isValid, getProps, dispatch, setInitial };
};

export default useForm;

const isValidParameter = (value, rules) => {
  if (rules == "") {
    return true;
  }

  let rulesArray = rules.split("|");

  return isAllTrue(
    rulesArray.map((rule) => {
      return checkRule(value, rule);
    })
  );
};

const checkRule = (value, rule) => {
  if (rule == "") {
    return true;
  }
  let arrRegex = /\[([^)]+)\]/g;

  let ruleValues = [];
  let finalRule = rule;
  if (rule.match(arrRegex)) {
    let inside_arr = arrRegex.exec(rule);
    finalRule = rule.replace(inside_arr[0], "");
    ruleValues = inside_arr[1].split(",");
  }

  return rules[finalRule](value, ...ruleValues);
};
