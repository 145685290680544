import toAdmin from "@utils/toAdmin";

export const getProfilersDataPerCountry = () => {
  return toAdmin.get("survey", "profilers");
};

export const getProfilers = ({ country }) => {
  return toAdmin.get("survey", "profilers/" + country);
};

export const getProfiler = ({ country, profiler_id }) => {
  return toAdmin.get("survey", "profilers/" + country + "/" + profiler_id);
};
