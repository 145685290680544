export class Position {
  x = 0;
  y = 0;
  z = 0;
  rx = 0;
  ry = 0;
  rz = 0;
  rw = 0;

  constructor(x = 0, y = 0, z = 0, rx = 0, ry = 0, rz = 0, rw = 0) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.rx = rx;
    this.ry = ry;
    this.rz = rz;
    this.rw = rw;
  }

  setPosition(position) {
    this.x = position.x;
    this.y = position.y;
    this.z = position.z;
    this.rx = position.rx;
    this.ry = position.ry;
    this.rz = position.rz;
    this.rw = position.rw;
  }
}
