import { useEffect, createContext, useState, useContext, useMemo } from "react";

import { useSelector } from "react-redux";

import { SocketProvider, useSocket } from "../hooks/useSocket";

import { MultiPlayerProvider } from "./MultiPlayerProvider";

const ServerProvider = ({ children }) => {
  //send data with socket
  const token = useSelector((state) => state.token);

  return (
    <SocketProvider url="https://api.master.yflow.io:12004">
      <MultiPlayerProvider>{children}</MultiPlayerProvider>
    </SocketProvider>
  );
};

export default ServerProvider;
