import httpbuildquery from "@utils/httpbuildquery";
import { axios } from "./axios";
import store from "@store";
export const get = async (url, params = null, options = {}) => {
  let full_url = url + (params ? "?" + httpbuildquery(params) : "");
  return await axios.get(full_url);
};

export const post = async (url, params = [], options = {}) => {
  return await axios.request({
    url,
    method: "post",
    data: params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...options?.headers,
    },
  });
};

export const put = async (url, params = [], options = {}) => {
  return await axios.request({
    url,
    method: "put",
    data: params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const dlt = async (url, params = [], options = {}) => {
  return await axios.request({
    url,
    method: "delete",
    data: params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const request = async (method, url, params = [], options = {}) => {
  switch (method) {
    case "get":
      return await get(url, params, options);
    case "post":
      return await post(url, params, options);
    case "put":
      return await put(url, params, options);
    case "delete":
      return await dlt(url, params, options);
    default:
      return await get(url, params, options);
  }
};
export default { get, post, request, dlt };
