import { Tab as MuiTab, Tabs, Box, Typography } from "@mui/material";

const Tab = (props) => {
  const { tabs, children, tab, setTab } = props;

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((value) => {
            return <MuiTab label={value} />;
          })}
        </Tabs>
      </Box>
      {children.map((child, index) => {
        return (
          <TabPanel value={tab} index={index}>
            {child}
          </TabPanel>
        );
      })}
    </>
  );
};

export default Tab;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};
