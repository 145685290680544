import useRecorder from "./hooks/useRecorder";
const TestAudio = () => {
  const recorder = useRecorder();
  return (
    <div>
      <button
        onClick={() => {
          recorder.startRecording((buffer) => {
            console.log(buffer);
          });
        }}
      >
        Start
      </button>
      <button onClick={recorder.stopRecording}>Stop</button>
      {recorder.recording ? <h1>Recording</h1> : <h1>Not Recording</h1>}
    </div>
  );
};

export default TestAudio;
