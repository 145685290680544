import { useState } from "react";
//import auth from "@features/auth/api/auth";
import { Button, TextField, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import useLogin from "../../hooks/login";
const LoginForm = (props) => {
  const { onLogin } = props;

  const { mutation } = useLogin({ redirect: onLogin });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;
    mutation.mutate({ email: email.value, password: password.value });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <h2>Login</h2>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="password"
        label="Password"
        name="password"
        autoComplete="password"
        type="password"
        autoFocus
      />
      <LoadingButton
        variant="contained"
        color="primary"
        fullWidth
        type="submit"
        loading={mutation.isLoading}
      >
        Sign In
      </LoadingButton>
    </Box>
  );
};

export default LoginForm;
