import * as api from "./../../api";
import React, { useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Container,
  Typography,
  Chip,
  Divider,
  Button,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableBody,
  Paper,
  TableContainer,
  Alert,
} from "@mui/material";
import Tab from "@components/Tab";
import { DataGrid } from "@mui/x-data-grid";
import { uuidv4 } from "@utils/uuid";
import { Key } from "@mui/icons-material";
import MonacoEditor from "@uiw/react-monacoeditor";

const ManagerContext = React.createContext();

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManagerPage = (props) => {
  const { database } = props;

  const db = useDBManager(database);

  const { data: tables, isLoading: tablesLoading } = db.tablesQuery;

  return (
    <ManagerContext.Provider value={db}>
      <Box p={2}>
        <Typography variant="h4">Database Manager</Typography>
        {tablesLoading ? "Loading..." : <TableList />}

        {db.selectedTable != null ? (
          <TableOver />
        ) : (
          <Box>
            <Typography variant="h5">Select a table</Typography>
          </Box>
        )}
      </Box>
    </ManagerContext.Provider>
  );
};

const TableOver = () => {
  const db = React.useContext(ManagerContext);

  const handleChange = (event, newValue) => {
    db.setTab(newValue);
  };

  return (
    <Box>
      <Tab
        tabs={["Browse", "Structure", "Query"]}
        tab={db.tab}
        setTab={db.setTab}
      >
        <BrowseWindow />

        <StructureWindow />
        <QueryWindow />
      </Tab>
    </Box>
  );
};

const QueryWindow = (props) => {
  const db = React.useContext(ManagerContext);

  const [value, setValue] = React.useState("");
  const editorRef = React.useRef();
  useEffect(() => {
    setValue(db.queryText);
  }, []);
  const handleChange = (value) => {
    db.setQueryText(value);
  };
  const runQuery = () => {
    const query = editorRef.current.editor.getValue();
    db.runQuery(query);
  };
  return (
    <Box>
      <Typography variant="h5">Write custom queries</Typography>
      <MonacoEditor
        ref={editorRef}
        height={200}
        width="100%"
        value={value}
        language="sql"
        onChange={handleChange}
        options={{
          theme: "vs-light",
          automaticLayout: true,
          padding: {
            top: 10,
          },
          fontSize: 14,
          fontFamily: "Cereal",
          autoIndent: true,
        }}
      />
      <Button variant="contained" onClick={runQuery}>
        Run
      </Button>
      <br />
      <br />
      <Divider />
      <br />
      <QueryResult />
    </Box>
  );
};

const QueryResult = (props) => {
  const db = React.useContext(ManagerContext);

  if (db.queryMutation.isLoading) {
    return "Loading";
  }
  if (db.queryMutation.isError) {
    return (
      <Alert severity="error">
        {JSON.stringify(db.queryMutation.error.response.error)}
      </Alert>
    );
  }
  if (!db.queryMutation.data) {
    return null;
  }

  const data = db.queryMutation.data;

  return (
    <Container>
      <Alert severity="success">{data?.query}</Alert>
      <QueryDataTable data={data?.data || []} fields={data?.fields || []} />
    </Container>
  );
};

const StructureWindow = (props) => {
  const db = React.useContext(ManagerContext);

  return (
    <Box>
      <Typography variant="h5">Table structure</Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>Extra</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {db.currentTable.map((item, index) => {
              return (
                <TableRow>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {item.COLUMN_NAME}
                      <ColumnKey item={item} />
                    </Box>
                  </TableCell>
                  <TableCell>{item.COLUMN_TYPE}</TableCell>
                  <TableCell>{item.COLUMN_COMMENT}</TableCell>
                  <TableCell>{item.EXTRA}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
const ColumnKey = ({ item }) => {
  const getColumnKey = () => {
    switch (item.COLUMN_KEY) {
      case "PRI":
        return "primary";
      case "MUL":
        return "multi";
      default:
        return null;
    }
  };

  const icon = {
    primary: <Key htmlColor="gold" />,
    multi: <Key htmlColor="#ccc" />,
  };

  return icon[getColumnKey()];
};
const BrowseWindow = (props) => {
  const db = React.useContext(ManagerContext);

  return (
    <Box>
      <Typography variant="h5">{db.selectedTable}</Typography>

      <QueryDataTable
        data={db.tableData?.data || []}
        fields={db.getCurrentTableColumns()}
      />
    </Box>
  );
};

const QueryDataTable = (props) => {
  const { fields, data = [] } = props;

  const gridRef = React.useRef();
  const columns = fields.map((column) => {
    return {
      field: column,
      headerName: column,
      editable: true,
      width: 150,

      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    };
  });

  const rows = data.map((row) => {
    let temp = row;
    if (!Object.keys(row).includes("id")) {
      temp["id"] = uuidv4();
    }
    return temp;
  });

  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        ref={gridRef}
        rows={rows}
        columns={columns}
        pageSize={20}
        checkboxSelection={true}
      />
    </div>
  );
};

const TableList = (props) => {
  const db = React.useContext(ManagerContext);

  const tables = db.tablesQuery.data;

  const tables_names = Object.keys(tables);

  const selectTable = (table) => {
    db.setSelectedTable(table);
  };

  return (
    <Box width="100%" overflowX="scroll">
      {tables_names.map((table_name) => {
        return (
          <Chip
            onClick={() => selectTable(table_name)}
            sx={{ margin: 0.5 }}
            size="small"
            label={table_name}
            color={db.selectedTable == table_name ? "primary" : "default"}
          />
        );
      })}
    </Box>
  );
};

const useDBManager = (database) => {
  const [selectedTable, setSelectedTable] = React.useState(null);
  const [tab, setTab] = React.useState(0);
  const [queryText, setQueryText] = React.useState("");
  const tablesQuery = useQuery(["tables", database], () =>
    api.getTables(database)
  );

  const queryMutation = useMutation((query) => api.runQuery(database, query), {
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const runQuery = (query) => {
    queryMutation.mutate(query);
  };

  const currentTable = React.useMemo(() => {
    if (selectedTable == null) {
      return null;
    }
    return tablesQuery.data[selectedTable];
  }, [selectedTable]);

  const getCurrentTableColumns = React.useCallback(() => {
    if (selectedTable == null) {
      return null;
    }
    return currentTable.map((column) => column.COLUMN_NAME);
  }, [selectedTable]);

  const tableData = useQuery(
    ["table", selectedTable],
    () => api.getTableData(database, selectedTable),
    {
      enabled: selectedTable != null,
    }
  );

  return {
    tablesQuery,
    tableData,
    selectedTable,
    setSelectedTable,
    currentTable,
    tab,
    setTab,
    queryMutation,
    runQuery,
    getCurrentTableColumns,
    queryText,
    setQueryText,
  };
};

export default ManagerPage;
