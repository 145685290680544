import React, { useState, useEffect, useRef } from "react";
import Peer from "simple-peer";
import io from "socket.io-client";

const VideoChat = () => {
  const [stream, setStream] = useState();
  const [mediaOption, setMediaOption] = useState("camera");
  const [peers, setPeers] = useState([]);
  const socketRef = useRef();

  const displayMediaOptions = {
    video: {
      cursor: "always",
      height: 1000,
      width: 1200,
    },
    audio: true,
  };

  const cameraMediaOptions = { video: true, audio: true };

  const noneMediaOptions = { video: false, audio: true };

  const updateMediaStream = async () => {
    const newStream = await (async () => {
      switch (mediaOption) {
        case "camera":
          return navigator.mediaDevices.getUserMedia(cameraMediaOptions);
        case "screen":
          return navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
        case "none":
          return navigator.mediaDevices.getUserMedia(noneMediaOptions);
        default:
          throw new Error("Invalid media option");
      }
    })();

    setStream(newStream);

    peers.forEach((peer) => {
      peer.replaceTrack(
        stream.getTracks().find((track) => track.kind === "video"),
        newStream.getTracks().find((track) => track.kind === "video"),
        stream
      );
    });
  };

  useEffect(() => {
    socketRef.current = io.connect("https://my-server.com");

    navigator.mediaDevices
      .getUserMedia(cameraMediaOptions)
      .then((stream) => setStream(stream));

    socketRef.current.on("all users", (users) => {
      const newPeers = [];

      users.forEach((user) => {
        const peer = new Peer({
          initiator: true,
          trickle: false,
          stream,
        });

        peer.on("signal", (signal) => {
          socketRef.current.emit("sending signal", {
            signal,
            userToSignal: user.id,
          });
        });

        peer.on("stream", (stream) => {
          // Handle stream
        });

        socketRef.current.on("receiving signal", (data) => {
          if (data.userToSignal === user.id) {
            peer.signal(data.signal);
          }
        });

        newPeers.push(peer);
      });

      setPeers(newPeers);
    });

    // Handle other socket events
  }, []);

  return (
    <div>
      <div>
        <button onClick={() => setMediaOption("camera")}>Camera</button>
        <button onClick={() => setMediaOption("screen")}>Screen</button>
        <button onClick={() => setMediaOption("none")}>None</button>
      </div>

      {stream && <video srcObject={stream} autoPlay muted />}
    </div>
  );
};

export default VideoChat;
