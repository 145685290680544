import socketIO from "socket.io-client";
import { createContext, useContext } from "react";

export const Socket = createContext();

const useSocket = (url) => {
  const socket = socketIO.connect(url);

  return { socket };
};

export const SocketProvider = (props) => {
  const { url, children } = props;
  const socket = useSocket(url);

  return <Socket.Provider value={socket}>{children}</Socket.Provider>;
};
