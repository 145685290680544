import { Avatar } from "@mui/material";
import { stringToColor } from "@utils/stringToColor";
const UserAvatar = (props) => {
  const { name } = props;

  return <Avatar {...stringAvatar(name)} />;
};

export default UserAvatar;

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
