import React, { Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Collapse,
  Box,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

import { useQuery } from "@tanstack/react-query";
import { getProfilersDataPerCountry } from "../../../api";

import PageLayout from "@features/platforms/layout/PageLayout";
const Dashboard = (props) => {
  const navigate = useNavigate();

  const { data, isLoading, error } = useQuery(
    ["profilersDataPerCountry"],
    () => getProfilersDataPerCountry(),
    {
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Container maxWidth="xl">
      <h1>Profiling</h1>
      <CountriesTable data={data} />
    </Container>
  );
};

const CountriesTable = (props) => {
  const navigate = useNavigate();
  let { data } = props;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Country</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow key={row.country}>
              <TableCell>{row.country}</TableCell>
              <TableCell>{row.total_questions}</TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    navigate(row.country);
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  <ChevronRight />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Dashboard;
