import toAdmin from "@utils/toAdmin";

export const getMethods = () => {
  return toAdmin.get("payout", "methods");
};
export const getMethod = (method_id) => {
  return toAdmin.get("payout", `methods/${method_id}`);
};

export const createMethod = (data) => {
  return toAdmin.post("payout", "methods", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getMethodItems = (method_id) => {
  return toAdmin.get("payout", `methods/${method_id}/items`);
};

export const getCurrencies = () => {
  return toAdmin.get("payout", "currencies");
};

export const createMethodItem = (data) => {
  return toAdmin.post("payout", `methods/${data.method_id}/items`, data);
};

export const updateMethodItem = (data) => {
  return toAdmin.put(
    "payout",
    `methods/${data.method_id}/items/${data.method_item_id}`,
    data
  );
};

export const getMethodCountries = (method_id) => {
  return toAdmin.get("payout", `methods/${method_id}/countries`);
};

export const addMethodCountries = (data) => {
  return toAdmin.post("payout", `methods/${data.method_id}/countries`, {
    countries: data.countries,
  });
};

export const removeMethodCountry = (data) => {
  return toAdmin.dlt(
    "payout",
    `methods/${data.method_id}/countries/${data.country}`
  );
};
