import React, { useRef, useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useSkinnedMeshClone } from "../hooks/useSkinnedMeshClone";

export function PlayerWindowModel(props) {
  const { avatar } = props;
  const headRef = useRef();

  useFrame(() => {});

  const scene = useGLTF("/" + avatar).scene.clone();
  const objProps = {
    object: scene,
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    scale: [1, 1, 1],
  };
  return (
    <group {...props} scale={10} dispose={null}>
      <primitive {...objProps} />
    </group>
  );
}

useGLTF.preload("/6359acfb0de23ad97899564e.glb");
