import { Container, Box } from "@mui/material";
import TopNavigationOutside from "../TopNavigation";
import Footer from "../Footer";
import React, { Fragment } from "react";
import "./style/index.scss";

const PageLayout = (props) => {
  const { children } = props;

  const pages = [
    {
      title: "Login",
      path: "/login",
    },
  ];
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: 0,
        gridTemplateRows: "auto",
        gridTemplateAreas: `"header header header header"
                            "main main main main"
                            "footer footer footer footer"`,
      }}
    >
      <Box sx={{ gridArea: "header" }}>
        <TopNavigationOutside pages={pages} />
      </Box>
      <Box
        sx={{
          gridArea: "main",
          bgcolor: "white.main",
        }}
      >
        {children}
      </Box>
      <Box sx={{ gridArea: "footer", bgcolor: "background.main" }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default PageLayout;
