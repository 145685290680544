import "./style/index.scss";
import React, { Fragment, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  getMethodItems,
  addMethodCountries,
  removeMethodCountry,
  getMethod,
  getMethodCountries,
} from "./../../api";
import {
  Container,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  Breadcrumbs,
  Typography,
  Box,
  Button,
  Tabs,
  Dialog,
  Tab,
  DialogContent,
  Alert,
} from "@mui/material";
import { ChevronRight, Edit, Add, Delete } from "@mui/icons-material";
import { CountryList, ConfirmAction } from "@components";
import Flag from "react-world-flags";
import CreateMethod from "./CreateMethod";
import CreateMethodItem from "./CreateMethodItem";
import MethodItemsSelect from "../../components/MethodItemsSelect";
import { SnackContext } from "@providers";
const SpecificMethodPage = (props) => {
  const { method_id } = useParams();

  const { data: method, ...methodQuery } = useQuery(
    ["method", method_id],
    () => {
      return getMethod(method_id);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: methodItems, ...methodItemsQuery } = useQuery(
    ["method_items", method_id],
    () => {
      console.log("get items");
      return getMethodItems(method_id);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: methodCountries, ...methodCountriesQuery } = useQuery(
    ["method_countries", method_id],
    () => {
      return getMethodCountries(method_id);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  if (
    methodQuery.isLoading ||
    methodItemsQuery.isLoading ||
    methodCountriesQuery.isLoading
  ) {
    return "Loading";
  }

  return (
    <Container maxWidth="xl">
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          sx={{
            ":hover": {
              cursor: "pointer",
              color: "primary.main",
            },
          }}
        >
          <NavLink to="/platform/payout/methods">Payout Methods</NavLink>
        </Typography>
        <Typography color="text.primary">{method.name}</Typography>
      </Breadcrumbs>
      <br />
      <Box display="flex">
        <img src={method.logo} width={40} height={40} />
        <Typography variant="h4" sx={{ marginLeft: 2 }}>
          {method.name}
        </Typography>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="General Settings" />
          <Tab label="Available Countries" />
          <Tab label="Method Items" />
        </Tabs>
      </Box>

      <GeneralTab tab={tab} setTab={setTab} />
      <AvailableCountriesTab tab={tab} />
      <MethodItemsTab tab={tab} />
    </Container>
  );
};

export default SpecificMethodPage;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

//---General

const GeneralTab = (props) => {
  const { tab, setTab } = props;

  const { method_id } = useParams();

  const queryClient = useQueryClient();

  const methodItems = queryClient.getQueryData(["method_items", method_id]);
  const methodCountries = queryClient.getQueryData([
    "method_countries",
    method_id,
  ]);
  const method = queryClient.getQueryData(["method", method_id]);

  return (
    <TabPanel value={tab} index={0}>
      <Typography variant="h5">General Settings</Typography>
      {methodItems.length == 0 && (
        <Alert
          severity="warning"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                setTab(2);
              }}
            >
              Create Default item
            </Button>
          }
        >
          You need to add the "DEFAULT" method item to be able to use this
          method
        </Alert>
      )}
      <br />
      {methodCountries.length == 0 && (
        <Alert severity="warning">
          You need to add at least one country to be able to use this method
        </Alert>
      )}
    </TabPanel>
  );
};

//---countries

const AvailableCountriesTab = (props) => {
  const { tab } = props;

  const { method_id } = useParams();

  const queryClient = useQueryClient();

  const methodItems = queryClient.getQueryData(["method_items", method_id]);
  const methodCountries = queryClient.getQueryData([
    "method_countries",
    method_id,
  ]);
  const method = queryClient.getQueryData(["method", method_id]);

  return (
    <TabPanel value={tab} index={1}>
      <Typography variant="h5">Available Countries</Typography>
      <Typography variant="caption">
        All the countries this payout method is available in.
      </Typography>
      <MethodCountriesTable
        data={{
          method,
          countries: methodCountries,
          items: methodItems,
        }}
      />
    </TabPanel>
  );
};
const MethodCountriesTable = (props) => {
  const [openAddCountry, setOpenAddCountry] = React.useState(false);
  const [openSubmitCountries, setOpenSubmitCountries] = React.useState(false);
  const [countries, setCountries] = React.useState([]);

  const snack = React.useContext(SnackContext);

  const { data } = props;

  const queryClient = useQueryClient();
  const deleteCountryMutation = useMutation(removeMethodCountry, {
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ["method_countries", data.method.method_id],
        (old) => {
          return old.filter((value) => value.country != variables.country);
        }
      );
      snack.setSnack({
        title: "Country removed",
        severity: "success",
      });
    },
    onError: (error) => {
      snack.setSnack({
        title: "Error",
        severity: "error",
      });
    },
  });

  const addCountryMutation = useMutation(addMethodCountries, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(["method_countries"], (old) => {
        return old.map((value) => {
          if (value.country == variables.countries[0].country) {
            return {
              ...value,
              method_item_id: variables.countries[0].method_item_id,
            };
          } else {
            return value;
          }
        });
      });
      snack.setSnack({
        title: "Method item changed",
        severity: "success",
      });
    },
    onError: (error) => {
      snack.setSnack({
        title: "Error",
        severity: "error",
      });
    },
  });

  const updateCountryMethod = (country, method_item_id) => {
    addCountryMutation.mutate({
      method_id: data.method.method_id,
      countries: [{ country, method_item_id }],
    });
  };

  const deleteCountry = (country) => {
    deleteCountryMutation.mutate({
      method_id: data.method.method_id,
      country,
    });
  };
  return (
    <Box>
      <TableContainer
        variant="outlined"
        sx={{
          border: "1px solid #e0e0e0",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Country</TableCell>
              <TableCell>Method Item</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.countries?.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Flag code={item.country} height={16} />
                    {item.country}
                  </TableCell>
                  <TableCell>
                    <MethodItemsSelect
                      methodItems={data.items}
                      value={item.method_item_id}
                      onChange={(value) => {
                        updateCountryMethod(item.country, value);
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      startIcon={<Delete />}
                      size="small"
                      onClick={() => {
                        deleteCountry(item.country);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        size="small"
        onClick={() => {
          setOpenAddCountry(true);
        }}
      >
        Add Country
      </Button>
      {openAddCountry && (
        <CountryList
          open={openAddCountry}
          onClose={() => {
            setOpenAddCountry(false);
          }}
          onSubmit={(countries) => {
            setCountries(countries);
            setOpenSubmitCountries(true);
          }}
          notAllowedCountries={data?.countries?.map((item) => {
            return item.country;
          })}
        />
      )}
      {openSubmitCountries && (
        <SubmitCountries
          open={openSubmitCountries}
          onClose={() => {
            setOpenSubmitCountries(false);
          }}
          countries={countries}
          methodId={data.method.method_id}
        />
      )}
    </Box>
  );
};

const SubmitCountries = (props) => {
  const { open, onClose, countries, methodId } = props;

  const queryClient = useQueryClient();

  const addCountries = useMutation(addMethodCountries, {
    onSuccess: (data) => {
      queryClient.refetchQueries(["method_countries", methodId]);
      onClose();
    },
    onError: (data) => {
      console.log(data);
    },
  });

  const methodItems = queryClient.getQueryData(["method_items", methodId]);

  const [countryMethodItems, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "add":
        return [...state, action.payload];
      case "remove":
        return state.filter((item) => item.country !== action.payload.country);
      case "update":
        return state.map((item) => {
          if (item.country === action.payload.country) {
            return {
              ...item,
              method_item_id: action.payload.method_item_id,
            };
          }
          return item;
        });
      case "set":
        return action.payload;
      default:
        return state;
    }
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      let defaultItem = methodItems.find(
        (value) => value.item_name == "DEFAULT"
      );
      dispatch({
        type: "set",
        payload: countries.map((country) => {
          return {
            country,
            method_item_id: defaultItem?.method_item_id,
          };
        }),
      });
    }
  }, []);

  const submit = () => {
    addCountries.mutate({
      method_id: methodId,
      countries: countryMethodItems,
    });
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogContent>
        <Box
          sx={{ flexDirection: "column", m: "auto", width: "fit-content" }}
          display="flex"
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>Method Item</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {countryMethodItems?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Flag code={item.country} height={16} />
                        {item.country}
                      </TableCell>
                      <TableCell>
                        <MethodItemsSelect
                          methodItems={methodItems}
                          isDefaultInitial={true}
                          value={item.method_item_id}
                          onChange={(value) => {
                            dispatch({
                              type: "update",
                              payload: { ...item, method_item_id: value },
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="contained" fullWidth onClick={submit}>
            Submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
//-----

//----method items-----
const MethodItemsTab = (props) => {
  const { tab } = props;
  const { method_id } = useParams();
  const [showEditMethod, setShowEditMethod] = React.useState(false);
  const [editMethodId, setEditMethodId] = React.useState(null);
  const [showCreateMethod, setShowCreateMethod] = React.useState(false);

  const queryClient = useQueryClient();

  const methodItems = queryClient.getQueryData(["method_items", method_id]);

  const methodItemsEmptyBody = () => {
    return (
      <>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            setShowCreateMethod(true);
          }}
        >
          Create DEFAULT method item
        </Button>
      </>
    );
  };
  const body = () => {
    return (
      <>
        <MethodItemsTable
          methodItems={methodItems}
          action={(type, item) => {
            if (type === "edit") {
              setEditMethodId(item.method_item_id);
              setShowEditMethod(true);
            }
          }}
        />
        <br />
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Add />}
          onClick={() => {
            setShowCreateMethod(true);
          }}
        >
          Add New Item
        </Button>
      </>
    );
  };

  return (
    <TabPanel value={tab} index={2}>
      <Typography variant="h5">Method Items</Typography>
      <Typography variant="caption">Customize for unique use cases.</Typography>
      <br />
      <br />
      {methodItems.length == 0 ? methodItemsEmptyBody() : body()}
      <CreateMethodPageDialog
        open={showCreateMethod}
        handleClose={() => {
          setShowCreateMethod(false);
        }}
      />
      <EditMethodPageDialog
        open={showEditMethod}
        methodItemId={editMethodId}
        handleClose={() => {
          setShowEditMethod(false);
        }}
      />
    </TabPanel>
  );
};
const MethodItemsTable = (props) => {
  const { methodItems, action = () => {} } = props;

  return (
    <Box>
      <TableContainer
        sx={{
          border: "1px solid #e0e0e0",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {methodItems?.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.item_name}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.currency}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      startIcon={<Edit />}
                      onClick={() => {
                        action("edit", item);
                      }}
                      size="small"
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const CreateMethodPageDialog = (props) => {
  const { open, handleClose, methodItems } = props;
  const { method_id } = useParams();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogContent>
        <Box
          sx={{ flexDirection: "column", m: "auto", width: "fit-content" }}
          display="flex"
        >
          <CreateMethodItem
            methodId={method_id}
            methodItemId={null}
            onSuccess={() => {
              handleClose();
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const EditMethodPageDialog = (props) => {
  const { open, handleClose, methodItemId } = props;
  const { method_id } = useParams();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogContent>
        <Box
          sx={{ flexDirection: "column", m: "auto", width: "fit-content" }}
          display="flex"
        >
          <CreateMethodItem
            methodId={method_id}
            methodItemId={methodItemId}
            onSuccess={() => {
              handleClose();
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

//-----
