import "./style/index.scss";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { getMethods } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
import { Add, ChevronRight } from "@mui/icons-material";
const MethodsPage = (props) => {
  const { data, isLoading, error } = useQuery(["methods"], () => getMethods(), {
    refetchOnWindowFocus: false,
  });

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h5" mt={2} mb={2}>
          Methods
        </Typography>
        {isLoading ? "loading" : <MethodsGrid data={data} />}
      </Box>
    </Container>
  );
};

const MethodsGrid = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      {data.map((item, index) => {
        return (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <MethodCard
              item={item}
              onClick={() => {
                navigate(String(item.method_id));
              }}
            />
          </Grid>
        );
      })}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card variant="" style={{ height: "100%" }}>
          <CardContent sx={{ padding: 0, height: "100%" }}>
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate("add");
                }}
              >
                Add Method
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MethodsPage;

const MethodCard = (props) => {
  const { item, onClick } = props;
  return (
    <Card variant="outlined">
      <CardContent>
        <Box display="flex" sx={{ justifyContent: "space-between" }}>
          <Typography variant="subtitle1">{item.name}</Typography>
          <Box
            sx={{
              height: 50,
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "background.main",
              borderRadius: 2,
            }}
          >
            <img src={item.logo} width={30} height={30} />
          </Box>
        </Box>
        <Typography variant="caption">{item.description}</Typography>
      </CardContent>
      <CardActions
        sx={{
          borderTop: "1px solid #ddd",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <Button
          variant="outlined"
          size="small"
          endIcon={<ChevronRight />}
          onClick={onClick}
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
};
