import "./style/index.scss";
import * as constants from "@constants";
import React from "react";

import { IconButton, Link, Typography, Box, Container } from "@mui/material";

import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = (props) => {
  const links = [
    {
      title: "Login",
      path: "/login",
    },
  ];

  return (
    <footer>
      <Container maxWidth="lg">
        <Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
          <Link href="/" color="inherit" underline="none">
            <h3>{constants.BRAND}</h3>
          </Link>
          <Box component="nav" ml="auto">
            {links.map((link, index) => {
              return (
                <Link
                  href={link.path}
                  variant="body1"
                  className="footer-link"
                  color="textPrimary"
                >
                  {link.title}
                </Link>
              );
            })}
          </Box>
        </Box>
        <Box pt={1} pb={6} display="flex" flexWrap="wrap" alignItems="center">
          <Typography
            color="textSecondary"
            component="p"
            variant="caption"
            gutterBottom={false}
          >
            © 2022 {constants.BRAND} All rights reserved.
          </Typography>
          <Box ml="auto">
            <IconButton color="inherit" aria-label="LinkedIn">
              <LinkedInIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
