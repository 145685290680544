import { APIEditor } from "@components/APIDocs";

import { useQuery } from "@tanstack/react-query";
import { getPlatforms } from "../../api";
import { useMemo } from "react";
const ApiDocumentationEditor = ({ platform }) => {
  const { data } = useQuery(["platforms"], getPlatforms, {
    refetchOnWindowFocus: false,
  });

  const platformData = useMemo(() => {
    if (data) {
      return data.find((p) => p.platform_name === platform);
    }
  }, [data, platform]);

  if (!data || !platformData) {
    return null;
  }

  return <APIEditor file={platformData.api_docs_url} />;
};

export default ApiDocumentationEditor;
