import { LocalPolice, MilitaryTech } from "@mui/icons-material";
import { Badge } from "@mui/material";

const Roles = () => {};

export const roleIcons = {
  0: {
    name: "Master",
    Icon: <LocalPolice />,
    color: "primary",
  },
  1: {
    name: "Admin",
    Icon: <MilitaryTech />,
    color: "primary",
  },
  2: {
    name: "User",
    Icon: <Badge />,
    color: "#ccc",
  },
};

export const RoleIcon = ({ role }) => {
  const { Icon } = roleIcons[role];
  return <Icon />;
};
