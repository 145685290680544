import {
  QueryClient,
  QueryClientProvider as Provider,
} from "@tanstack/react-query";

const queryClient = new QueryClient();
export default queryClient;

export const QueryClientProvider = ({ children }) => {
  return <Provider client={queryClient}>{children}</Provider>;
};
