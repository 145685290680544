import { useBox, useSphere } from "@react-three/cannon";
import * as textures from "../images/textures";
import {
  Euler,
  NearestFilter,
  Quaternion,
  Vector3,
  RepeatWrapping,
} from "three";
import { useEffect, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Model } from "./Model";
export const Avatar = ({ position, avatar }) => {
  const avatarMesh = useRef();
  const rotationY = useRef(0);
  useEffect(() => {
    //set euler order to YXZ
    avatarMesh.current.rotation.order = "ZYX";
  }, []);

  useFrame(({ camera }) => {
    if (avatarMesh != undefined && position != undefined) {
      avatarMesh.current.position.lerp(
        new Vector3(position.rx, position.ry - 1, position.rz),
        0.2
      );

      avatarMesh.current.rotation.y = position.y + Math.PI;
    }
  });

  return (
    <mesh ref={avatarMesh} scale={0.6}>
      <Model avatar={avatar} />
    </mesh>
  );
};
