import { Container, Box, Toolbar } from "@mui/material";
import { createContext } from "react";
import React, { Fragment } from "react";
import SideBar from "@components/layout/SideBar";
import SideBarItems from "./SideBarItems";
const PageLayout = (props) => {
  const { children, sideBarItems = [], active = null } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <SideBar items={SideBarItems} />
      <Box
        sx={{
          bgcolor: "white.main",
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default PageLayout;
