import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { publicRoutes, privateRoutes, globalRoutes } from "./paths";
import testRoutes from "./tests";
import { useSelector } from "react-redux";

const Router = () => {
  const router = () =>
    createBrowserRouter([
      ...testRoutes,
      ...publicRoutes,
      ...privateRoutes.map((route, index) => {
        return {
          ...route,
          element: <PrivateRoute>{route.element}</PrivateRoute>,
        };
      }),
      ...globalRoutes,
    ]);

  return <RouterProvider router={router()} />;
};

export default Router;

const PrivateRoute = ({ children, ...rest }) => {
  const token = useSelector((state) => state.token);

  let auth = token != null;

  return auth ? children : <Navigate to="/" />;
};
