import * as api from "@lib/api";

export const getUserData = () => {
  console.log("user fetch");
  return api.get("/user/me");
};

export const updateUserData = (data) => {
  return api.put("/user/me", data);
};

export const getUsers = () => {
  return api.get("/user");
};
