import ProfilePage from "../pages/ProfilePage";
import DontClickPage from "../pages/DontClickPage/DontClickPage";
import UsersPage from "../pages/UsersPage";
const basePath = "/settings";
const routes = [
  {
    path: "/profile",
    element: <ProfilePage />,
    exact: true,
  },
  {
    path: "/users",
    element: <UsersPage />,
    exact: true,
  },
  {
    path: "/dontclick",
    element: <DontClickPage />,
    exact: true,
  },
].map((route) => ({
  ...route,
  path: basePath + route.path,
}));

export default routes;
