import { useSelector } from "react-redux";
import PageLayout from "../_shared/layout/PageLayout";
import { Box, Container } from "@mui/material";
import * as constants from "@constants";

const HomePage = () => {
  const token = useSelector((state) => state.token);
  return (
    <PageLayout>
      <Container maxWidth="xl">
        <Box
          fullwidth
          sx={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            height: "50vh",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div>
            <h1>{constants.APP_NAME}</h1>
          </div>
        </Box>
      </Container>
    </PageLayout>
  );
};

export default HomePage;
