import { useQuery } from "@tanstack/react-query";
const useApiFile = (file) => {
  let url = typeof file === "string" && validUrl(file) ? file : null;

  return useQuery(["apiFile"], () => {
    
    return fetch(url).then((res) => res.json());
  });
};
//regex valid url
const validUrl = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export default useApiFile;
