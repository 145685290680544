import {
  makeStyles,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import variables from "./_variables.scss";
import { components } from "./theme.components";

const colors = variables;
const palette = {
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
  },
  highlight: {
    main: colors.highlight,
    light: colors.highlightLight,
  },
  background: {
    main: colors.background,
  },
  highlightLight: {
    main: colors.highlightLight,
  },
  black: {
    main: "#000000",
  },
  white: {
    main: "#ffffff",
  },
};
let theme = createTheme({
  typography: {
    fontFamily: "Cereal",
    subtitle1: {
      fontFamily: "Cereal",
    },
    h1: {
      fontWeight: "bold",
      fontSize: "2.5rem",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "2rem",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "1.5rem",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "1.25rem",
    },
    h5: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    h6: {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  palette,
  components,
});

theme = responsiveFontSizes(theme);

export { theme };
