import PageLayout from "@outside/_shared/layout/PageLayout";
import { useNavigate } from "react-router-dom";
import LoginForm from "@features/auth/components/LoginForm";
import { Container } from "@mui/material";

const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <Container maxWidth="sm">
        <div
          style={{
            minHeight: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoginForm
            onLogin={() => {
              return navigate("/home");
            }}
          />
        </div>
      </Container>
    </PageLayout>
  );
};

export default LoginPage;
