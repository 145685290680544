import { createContext } from "react";
import useSnack from "./../hooks/snack";
import { SnackItem } from "./../components";
export const SnackContext = createContext();

const SnackBarProvider = ({ children }) => {
  const snackHook = useSnack();
  return (
    <SnackContext.Provider value={snackHook}>
      {children}

      <SnackItem
        open={snackHook.snack != null}
        handleClose={() => {
          snackHook.removeSnack();
        }}
        title={snackHook.snack?.title}
        severity={snackHook.snack?.severity}
        duration={snackHook.snack?.duration}
      />
    </SnackContext.Provider>
  );
};

export default SnackBarProvider;
