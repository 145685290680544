import "./style/index.scss";
import { useQuery } from "@tanstack/react-query";
import { getPlatforms } from "../../api";
import {
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

const PlatformCard = (props) => {
  const { platform, onClick = () => {} } = props;

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle2">{platform?.title}</Typography>
        <Typography variant="caption">{platform?.description}</Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}></Box>
      </CardContent>
      <CardActions
        sx={{
          borderTop: "1px solid #ddd",
          justifyContent: "space-between",
        }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => onClick(platform)}
        >
          <ChevronRight />
        </Button>
      </CardActions>
    </Card>
  );
};

export default PlatformCard;
