import { Container, Box, Toolbar } from "@mui/material";
import { createContext } from "react";
import TopNavigation from "../TopNavigation";
import SideBar from "../SideBar";
import MiniSideBar from "@components/layout/MiniSideBar";
import React, { Fragment } from "react";

import { Home, Public, Groups } from "@mui/icons-material";
import "./style/index.scss";

const PageLayout = (props) => {
  const { children } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <MiniSideBar open={open} setOpen={setOpen} items={SideBarItems} />
      <TopNavigation setOpenSidebar={setOpen} sidebarOpen={open} />

      <Box
        sx={{
          bgcolor: "white.main",
          flexGrow: 1,
        }}
      >
        <Toolbar style={{ minHeight: 70 }} />
        {children}
      </Box>
    </div>
  );
};

export default PageLayout;

const SideBarItems = [
  {
    title: "Home",
    link: "/home",
    icon: <Home />,
    name: "home",
  },
  {
    title: "Metaverse",
    link: "/metaverse",
    icon: <Public />,
    name: "metaverse",
  },
  {
    title: "Conference",
    link: "/conference",
    icon: <Groups />,
    name: "conference",
  },
];
