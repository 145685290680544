import { Box, Select, Button, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useMemo, useState, useContext } from "react";
import MethodItemsSelect from "../../components/MethodItemsSelect";
import MethodItemForm, { formSkeleton } from "../../components/MethodItemForm";
import * as api from "../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "@components/Form";
import { SnackContext } from "@providers";
const CreateMethodItem = (props) => {
  const { onSuccess, methodId, methodItemId = null } = props;

  const snack = useContext(SnackContext);

  const queryClient = useQueryClient();
  const methodItems = queryClient.getQueryData(["method_items", methodId]);

  const [templateId, setTemplateId] = useState(methodItemId);

  const form = useForm({
    item_name: "required|string|min_length[2]",
    method_item: formSkeleton,
  });

  const templateItem = useMemo(() => {
    let item = methodItems.find((item) => item.method_item_id === templateId);
    return item;
  }, [templateId]);

  const editItem = useMemo(() => {
    let item = methodItems.find((item) => item.method_item_id === methodItemId);

    if (item != null)
      form.update({
        target: {
          name: "item_name",
          value: templateItem.item_name,
        },
      });
    return item;
  }, [methodItemId]);

  useEffect(() => {
    if (methodItems.length == 0) {
      form.update({
        target: {
          name: "item_name",
          value: "DEFAULT",
        },
      });
    }
  }, [methodItems]);

  const updateMutation = useMutation(api.updateMethodItem, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(["method_items", methodId], (old) => {
        return old.map((item) => {
          if (item.method_item_id == variables.method_item_id) {
            return { ...item, ...variables, item_name: variables.item_name };
          }
          return item;
        });
      });
      snack.setSnack({
        title: "Method item updated successfully",
        severity: "success",
      });
      onSuccess();
    },
    onError: (data) => {
      snack.setSnack({
        title: "Method item could not be updated",
        severity: "error",
      });
    },
  });

  const createMutation = useMutation(api.createMethodItem, {
    onSuccess: async (data) => {
      await queryClient.refetchQueries(["method_items", methodId], {
        active: true,
      });
      snack.setSnack({
        title: "Method item created successfully",
        severity: "success",
      });
      onSuccess();
    },
    onError: async (data) => {
      snack.setSnack({
        title: "Method item could not be created",
        severity: "error",
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.isValid.all) {
      if (methodItemId) {
        updateMutation.mutate({
          method_id: methodId,
          method_item_id: methodItemId,
          item_name: form.form.item_name,
          ...form.form.method_item,
        });
      } else {
        createMutation.mutate({
          method_id: methodId,
          item_name: form.form.item_name,
          ...form.form.method_item,
        });
      }
    }
  };

  const itemNameDisabled = () => {
    if (methodItems.length == 0) {
      return true;
    }
    return editItem != null ? editItem.item_name == "DEFAULT" : false;
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={2}
      component="form"
      onSubmit={handleSubmit}
    >
      {editItem == null ? (
        <Typography variant="h5">Create Method Item</Typography>
      ) : (
        <Typography variant="h5">
          Edit Method Item <strong>{editItem.item_name}</strong>
        </Typography>
      )}

      {methodItems.length > 0 && (
        <MethodItemsSelect
          methodItems={methodItems}
          value={templateId}
          onChange={setTemplateId}
          label="Select a template"
        />
      )}

      <TextField
        fullWidth
        label="Method Item Name"
        {...form.getProps("item_name")}
        disabled={itemNameDisabled()}
      />
      <MethodItemForm
        name="method_item"
        methodItem={templateItem}
        update={form.update}
      />
      <LoadingButton
        variant="contained"
        fullWidth
        type="submit"
        disabled={!form.isValid.all}
        loading={createMutation.isLoading || updateMutation.isLoading}
      >
        Submit
      </LoadingButton>
    </Box>
  );
};

export default CreateMethodItem;
