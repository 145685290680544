import { HomePage } from "@pages/outside/HomePage";
import LoginPage from "@pages/outside/LoginPage";
//private
import PageLayout from "@pages/inside/_shared/layout/PageLayout";
import DashboardPage from "@pages/inside/DashboardPage";
import globalpages from "@globalpages";
import { routes as platformRoutes } from "@features/platforms";
import { routes as settingsRoutes } from "@features/settings";
import { routes as conferenceRoutes } from "@features/conference";

import Metaverse from "@features/metaverse";
import TestAudio from "@components/audio/test";
const publicRoutes = [
  {
    path: "/",
    element: <HomePage />,
    exact: true,
  },
  {
    path: "/login",
    element: <LoginPage />,
    exact: true,
  },
  {
    path: "/recording",
    element: <TestAudio />,
    exact: true,
  },
];

const privateRoutes = [
  {
    path: "/home",
    element: <DashboardPage />,
    exact: true,
  },
  {
    path: "/metaverse",
    element: <Metaverse />,
    exact: true,
  },
  ...conferenceRoutes.map((route, index) => {
    return {
      ...route,
      path: "/conference" + route.path,
    };
  }),
  ...settingsRoutes.map((route, index) => {
    return {
      ...route,
      element: <PageLayout>{route.element}</PageLayout>,
    };
  }),
  ...platformRoutes.map((route, index) => {
    return {
      ...route,
      element: <PageLayout>{route.element}</PageLayout>,
    };
  }),
];

const globalRoutes = [
  {
    path: "*",
    element: <globalpages.notfound />,
    exact: true,
  },
];

export { publicRoutes, privateRoutes, globalRoutes };
