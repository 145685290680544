import {
  Email,
  Sms,
  Home,
  Description,
  Storage,
  Terminal,
} from "@mui/icons-material";
export default {
  title: "Communication",
  name: "communication",
  icon: "communication",
  segments: [
    {
      name: "Manage",
      type: "segment",
      children: [
        {
          type: "link",
          name: "email",
          title: "Email",
          link: "email",
          icon: <Email />,
        },
        {
          type: "link",
          name: "sms",
          title: "SMS",
          link: <Sms />,
          icon: "sms",
        },
      ],
    },
  ],
};
