import { Container, Typography } from "@mui/material";

const ClientsPage = () => {
  return (
    <Container maxWidth="xl">
      <Typography variant="h5" mt={2}>
        Clients
      </Typography>
    </Container>
  );
};

export default ClientsPage;
