import React, { Fragment } from "react";
import {
  Box,
  Button,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  Drawer,
  ListItemText,
  ListItemButton,
  ListSubheader,
  Typography,
  Divider,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Apps } from "@mui/icons-material";

const SideBar = (props) => {
  const { items = [], active = null } = props;
  const navigate = useNavigate();

  const isCurrentPath = (path) => {
    let current_path = window.location.pathname;
    return current_path == path;
  };
  const isSelected = (item) => {
    if (active == null) {
      return isCurrentPath(item.link);
    } else {
      return active == item.name;
    }
  };
  const renderSideBarItems = (item, index) => {
    if (item.type == "segment") {
      return (
        <>
          <ListSubheader component="div">
            <Typography variant="h6" mt={2} mb={2} sx={{ color: "#000" }}>
              {item.name}
            </Typography>
          </ListSubheader>

          {item.children.map(renderSideBarItems)}
          <Divider />
        </>
      );
    } else if (item.type == "link") {
      return (
        <ListItemButton
          selected={isSelected(item)}
          key={item.name}
          onClick={() => {
            navigate(item.link);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 3,
              justifyContent: "center",
              color: isSelected(item) ? "primary.main" : "text.secondary",
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.title}
            primaryTypographyProps={{
              variant: "body2",
              fontWeight: 500,
              color: isSelected(item) ? "primary.main" : "black",
            }}
          />
        </ListItemButton>
      );
    }
  };
  return (
    <Drawer
      sx={{
        flexShrink: 0,
        width: 240,
        zIndex: 99,
      }}
      variant="permanent"
      anchor="left"
      PaperProps={{
        sx: {
          left: 65,
        },
      }}
    >
      <Toolbar style={{ minHeight: 60 }} />
      <Box sx={{ width: 240 }}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h5">{items?.title}</Typography>
        </Box>
        <Divider />
        <List disablePadding>{items?.segments.map(renderSideBarItems)}</List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
