import React from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Popover,
  Button,
  TableCell,
  Link,
  Alert,
} from "@mui/material";
import { ExpandMore, Code } from "@mui/icons-material";

import CodeDialog from "./CodeDialog";
import useApiFile from "./hooks/apiFile";
import { useQuery } from "@tanstack/react-query";
import APIEditor from "./APIEditor";
import { useNavigate } from "react-router-dom";
const methodColors = {
  get: "success",
  post: "primary",
  put: "warning",
  delete: "error",
};

const APIDocsPage = (props) => {
  const { file: fileUrl } = props;

  const { data: file, isLoading, error } = useApiFile(fileUrl);

  const [openCode, setOpenCode] = React.useState(false);

  const ting = typeof fileUrl == "string" ? file : fileUrl;

  let start = {
    info: {},
    host: "",
    basePath: "",
    tags: [],
    schemes: [],
    paths: {},
    definitions: [],
    ...ting,
  };
  const {
    info = {},
    host = "",
    basePath = "",
    tags = [],
    schemes = [],
    paths = {},
    definitions = [],
  } = start;

  const renderFromTags = () => {
    return tags.map((tag) => {
      return (
        <>
          <Box paddingTop={3} paddingBottom={3}>
            <Typography variant="h6">{tag.name}</Typography>
            <Typography variant="body1">{tag.description}</Typography>
            <Box>{getPaths(tag.name)}</Box>
          </Box>
        </>
      );
    });
  };
  const getPaths = (tagName = null) => {
    let keys = Object.keys(paths);
    return keys.map((key) => {
      return Object.keys(paths[key]).map((method) => {
        if (tagName != null) {
          if (paths[key][method].tags?.includes(tagName)) {
            return (
              <Path
                definitions={definitions}
                path={key}
                method={method}
                data={paths[key][method]}
              />
            );
          } else {
            return null;
          }
        } else {
          return (
            <Path
              definitions={definitions}
              path={key}
              method={method}
              data={paths[key][method]}
            />
          );
        }
      });
    });
  };

  return (
    <Container maxWidth="xl">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={2}
      >
        <Typography marginRight={2} variant="h5">
          API Docs
        </Typography>
      </Box>

      <Typography variant="subtitle1">
        {info?.title} <strong>v{info?.version}</strong>
      </Typography>
      <Typography variant="body1">{info?.description}</Typography>

      {renderFromTags()}

      <Schemas schemas={definitions} />
      <CodeDialog
        open={openCode}
        handleClose={() => {
          setOpenCode(false);
        }}
        file={file}
      />
    </Container>
  );
};

const Path = (props) => {
  const { path, method, data, definitions } = props;
  const { summary, description, parameters, responses } = data;

  const [paramHover, setParamHover] = React.useState(null);

  const [expanded, setExpanded] = React.useState("");
  const handleChange = (key) => {
    if (expanded == key) {
      setExpanded("");
    } else {
      setExpanded(key);
    }
  };

  const pathParts = (() => {
    let params = [];
    let paths = path.split("/");
    paths.map((param) => {
      if (param.startsWith("{") && param.endsWith("}")) {
        let path_param = param.replace("{", "").replace("}", "");
        params.push({
          name: path_param,
          isParam: true,
        });
      } else {
        params.push({
          name: param,
          isParam: false,
        });
      }
    });
    return params;
  })();

  return (
    <Accordion
      variant="outlined"
      expanded={expanded === String(method + path)}
      onChange={() => {
        handleChange(String(method + path));
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box width={80}>
            <Chip label={method} color={methodColors[method]} />
          </Box>

          <Typography paddingLeft={2} variant="subtitle1">
            {pathParts.map((param) => {
              if (param.name == "") return null;
              if (param.isParam) {
                return (
                  <>
                    /
                    <Box
                      component="span"
                      color={
                        param.name == paramHover ? "highlight.main" : "black"
                      }
                    >
                      <strong>&#123;{param.name}&#125;</strong>
                    </Box>
                  </>
                );
              } else {
                return <Box component="span">/{param.name}</Box>;
              }
            })}
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.02)" }}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box paddingBottom={2}>
            <Typography variant="h6">{summary}</Typography>
            <Typography variant="body1">{description}</Typography>
          </Box>
          <Box paddingBottom={2}>
            <Typography variant="h6">Parameters</Typography>
            <Box display="flex" flexDirection="column">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>In</TableCell>
                    <TableCell>Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parameters.map((param) => {
                    return (
                      <TableRow
                        hover
                        onMouseOver={() => {
                          setParamHover(param.name);
                          console.log("hej");
                        }}
                        onMouseLeave={() => {
                          setParamHover(null);
                        }}
                      >
                        <TableCell>
                          {param.required && (
                            <Box
                              component="span"
                              color="red"
                              style={{ fontSize: 18 }}
                            >
                              *
                            </Box>
                          )}
                          {param.name}
                        </TableCell>
                        <TableCell>{param.description}</TableCell>
                        <TableCell>{param.in}</TableCell>
                        <TableCell>
                          <Box padding={1} borderRadius={2}>
                            {param?.schema != null ? (
                              <Schema
                                schema={param?.schema}
                                definitions={definitions}
                              />
                            ) : (
                              param.type
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Box>
          <Box paddingBottom={2}>
            <Typography variant="h6">Responses</Typography>
            <Grid container>
              <Grid Item xs={6}>
                <Table>
                  <TableBody>
                    {Object.keys(responses).map((key) => {
                      return (
                        <TableRow>
                          <TableCell>{key}</TableCell>
                          <TableCell>{responses[key].description}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
              <Grid Item xs={6}></Grid>
            </Grid>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const Schemas = (props) => {
  const [expanded, setExpanded] = React.useState("");
  const handleChange = (key) => {
    if (expanded == key) {
      setExpanded("");
    } else {
      setExpanded(key);
    }
  };

  const { schemas } = props;

  return (
    <Box>
      <Typography variant="h6">Schemas</Typography>
      {Object.keys(schemas).map((key) => {
        return (
          <Accordion
            variant="outlined"
            expanded={expanded === key}
            onChange={() => {
              handleChange(key);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="subtitle2">{key}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Schema schema={schemas[key]} definitions={schemas} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

const Schema = (props) => {
  const [schemeMenu, setSchemeMenu] = React.useState(null);
  const open = Boolean(schemeMenu);

  const { schema, definitions } = props;
  if (!schema) return null;
  if (schema?.type == "array") {
    return (
      <Box>
        <Typography variant="body2">Array of</Typography>
        <Box ml={2}>
          <Schema schema={schema.items} definitions={definitions} />
        </Box>
      </Box>
    );
  }
  if (schema?.type == "object") {
    return (
      <Box>
        <Typography variant="body2">Object</Typography>
        <Box ml={2}>
          {Object.keys(schema.properties).map((key) => {
            return (
              <Box key={key} padding={1}>
                <Typography variant="body2" color="primary">
                  {key}:
                </Typography>
                <Box ml={2}>
                  <Schema
                    schema={schema.properties[key]}
                    definitions={definitions}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }
  if (schema?.$ref != null) {
    const ref = schema.$ref.replace("#/definitions/", "");
    let object = definitions[ref];

    return (
      <Box>
        <Typography
          variant="body2"
          cursor="pointer"
          aria-controls={open ? `menu${ref}` : undefined}
          color="primary"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            setSchemeMenu(e.currentTarget);
          }}
          sx={{
            cursor: "pointer",
            color: "highlight.main",
          }}
        >
          <u>#{ref}</u>
        </Typography>
        <Popover
          id={`menu${ref}`}
          anchorEl={schemeMenu}
          open={open}
          onClose={() => {
            setSchemeMenu(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            style={{
              width: 300,
            }}
            padding={2}
          >
            <Schema schema={object} definitions={definitions} />
          </Box>
        </Popover>
      </Box>
    );
  }
  return (
    <Typography variant="body2" color="text.secondary">
      {schema.type}
    </Typography>
  );
};

export default APIDocsPage;
