import { Position } from "./Position";

class Player {
  constructor(id, position, token, avatar) {
    this.id = id;
    this.setPosition(position);
    this.token = token;
    this.avatar = avatar;
  }

  setPosition(position) {
    if (position == null) {
      this.position = new Position();
    } else {
      try {
        this.position.x = position.x;
        this.position.y = position.y;
        this.position.z = position.z;
        this.position.rx = position.rx;
        this.position.ry = position.ry;
        this.position.rz = position.rz;
        this.position.rw = position.rw;
      } catch (e) {
        this.position = new Position();
      }
    }
  }
  setPositionFromMovement(position, rotation) {
    this.position.x = position[0];
    this.position.y = position[1];
    this.position.z = position[2];
    this.position.rx = rotation.x;
    this.position.ry = rotation.y;
    this.position.rz = rotation.z;
    this.position.rw = rotation.w;
  }
}

export default Player;
