import * as React from "react";

import { Snackbar, Alert } from "@mui/material";

const SnackItem = (props) => {
  const { open, handleClose, title, severity, duration } = props;
  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {title}
      </Alert>
    </Snackbar>
  );
};

export default SnackItem;
