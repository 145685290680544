export const isAllTrue = (array) => {
  return (
    array.filter((value) => {
      if (typeof value == "object") {
        return !isAllTrue(Object.values(value));
      } else {
        return value == false;
      }
    }).length == 0
  );
};
