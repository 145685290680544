import React from "react";

/*
@snack object:
{
    title:String
    severity:success,error,warning,info
    duration:int -default:5000
}
*/

const snackReducer = (state, action) => {
  const defaultValue = {
    duration: 5000,
  };
  switch (action.type) {
    case "set":
      return { ...defaultValue, ...action.payload };
    case "remove":
      return null;
    default:
      return state;
  }
};

const useSnack = () => {
  const [snack, dispatch] = React.useReducer(snackReducer, null);

  const success = (title, duration = 5000) => {
    dispatch({
      type: "set",
      payload: {
        title,
        severity: "success",
        duration,
      },
    });
  };
  const error = (title, duration = 5000) => {
    dispatch({
      type: "set",
      payload: {
        title,
        severity: "error",
        duration,
      },
    });
  };
  const setSnack = ({ title, severity = "success", duration = 5000 }) => {
    dispatch({
      type: "set",
      payload: {
        title,
        severity,
        duration,
      },
    });
  };

  const removeSnack = () => {
    dispatch({
      type: "remove",
      payload: null,
    });
  };

  return { setSnack, removeSnack, snack, error, success };
};

export default useSnack;
