import {
  Card,
  CardContent,
  Container,
  Box,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import PageLayout from "./../../layout/PageLayout";
import { Dangerous, Send } from "@mui/icons-material";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const DontClickPage = () => {
  const queryClient = useQueryClient();

  return (
    <PageLayout>
      <Container maxWidth="xl">
        <Typography variant="h5">Please be careful mate</Typography>
        <Typography variant="subtitle1">
          Remember, there is no validation when clicking a button! Tryck seriöst
          inte för de gör det de säger.
        </Typography>
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={6} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="subtitle1">
                  Delete all data on Sveapanelen
                </Typography>

                <Button
                  variant="contained"
                  marginTop={2}
                  color="error"
                  startIcon={<Dangerous />}
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="subtitle1">
                  Delete all data on Ebuno
                </Typography>

                <Button
                  variant="contained"
                  marginTop={2}
                  color="error"
                  startIcon={<Dangerous />}
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="subtitle1">
                  Delete all data on Panelevolution
                </Typography>

                <Button
                  variant="contained"
                  marginTop={2}
                  color="error"
                  startIcon={<Dangerous />}
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="subtitle1">
                  Send email on Oliver doing bad things to Oliver's mother
                </Typography>

                <Button
                  variant="contained"
                  marginTop={2}
                  color="primary"
                  startIcon={<Send />}
                >
                  Send
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </PageLayout>
  );
};

export default DontClickPage;
