import { Email, Equalizer, QuestionAnswer } from "@mui/icons-material";
export default {
  title: "Survey",
  name: "survey",
  icon: "survey",
  segments: [
    {
      name: "",
      type: "link",
      name: "stats",
      title: "Stats",
      link: "/platform/survey/stats",
      icon: <Equalizer />,
    },
    {
      name: "Manage",
      type: "segment",
      children: [
        {
          type: "link",
          name: "profiling",
          title: "Profiling",
          link: "/platform/survey/profiling",
          icon: <QuestionAnswer />,
        },
      ],
    },
  ],
};
