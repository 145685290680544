import * as api from "@lib/api";

export const getTables = (platform) => {
  let endpoint = dbEndpoint(platform, "tables");
  return api.get(endpoint);
};

export const getTableData = (platform, table, options = {}) => {
  let endpoint = dbEndpoint(platform, `tables/${table}`);
  return api.get(endpoint, options);
};

export const runQuery = (platform, query) => {
  let endpoint = dbEndpoint(platform, "query");
  return api.post(endpoint, { query });
};

const dbEndpoint = (platform, endpoint) => {
  return `platforms/${platform}/database/${endpoint}`;
};
