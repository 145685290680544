import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronRight, MoveDown } from "@mui/icons-material";

import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Button,
  Box,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getProfilersDataPerCountry, getProfilers } from "../../../api";

import PageLayout from "@features/platforms/layout/PageLayout";
const Dashboard = (props) => {
  const { country } = useParams();

  const { data, isLoading, error } = useQuery(
    ["profilers", country],
    () => getProfilers({ country }),
    {
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Container maxWidth="xl">
      <h1>Profiling</h1>
      <CountriesTable data={data} />
    </Container>
  );
};

const CountriesTable = (props) => {
  let { data } = props;
  const [openRow, setOpenRow] = React.useState(null);

  const navigate = useNavigate();
  const handleClick = (id) => {
    setOpenRow(openRow === id ? null : id);
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Profiler ID</TableCell>
            <TableCell>Question</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Collapse</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <React.Fragment key={row.id}>
              <TableRow key={row.profiler_id}>
                <TableCell>{row.profiler_id}</TableCell>
                <TableCell>{row.question}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.category.title}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      navigate(row.profiler_id);
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <ChevronRight />
                  </Button>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Dashboard;
