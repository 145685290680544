const MuiToolbar = {
  styleOverrides: {
    // Name of the slot
    root: (props) => {
      const { ownerState } = props;
      return {
        boxShadow: "none",
        minHeight: "auto",
      };
    },
  },
};
const MuiAppBar = {
  styleOverrides: {
    // Name of the slot
    root: (props) => {
      const { ownerState } = props;
      return {
        boxShadow: "none",
      };
    },
  },
};

export { MuiToolbar, MuiAppBar };
