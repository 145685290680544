import React from "react";
import {
  Box,
  Container,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Image } from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { createMethod } from "../../api";
import { SnackContext } from "@providers";
import { useNavigate } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import MethodItemForm from "../../components/MethodItemForm";

const AddMethodPage = () => {
  const [step, setStep] = React.useState(0);
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box>
        <Box justifyContent="center" display="flex">
          <h1>Add Method</h1>
        </Box>
        <General
          onSuccess={(data) => {
            navigate("/platform/payout/methods/" + data.method_id);
          }}
        />
      </Box>
    </Container>
  );

  return (
    <Container maxWidth="sm">
      <Box>
        <Box justifyContent="center" display="flex">
          <h1>Add Method</h1>
        </Box>
        <Stepper activeStep={step} alternativeLabel>
          <Step key="general">
            <StepLabel>Create</StepLabel>
          </Step>
          <Step activeStep={step} key="default_method">
            <StepLabel>Details</StepLabel>
          </Step>
          <Step activeStep={step} key="available_countries">
            <StepLabel>Countries</StepLabel>
          </Step>
        </Stepper>

        <StepTab activeStep={step} step={0}>
          <General
            nextStep={() => {
              setStep(1);
            }}
          />
        </StepTab>
        <StepTab activeStep={step} step={1}>
          <MethodItem
            nextStep={() => {
              setStep(2);
            }}
          />
        </StepTab>
        <StepTab activeStep={step} step={2}>
          <Countries
            nextStep={() => {
              setStep(3);
            }}
          />
        </StepTab>
        <Button
          onClick={() => {
            setStep((state) => state + 1);
          }}
        >
          Next
        </Button>
      </Box>
    </Container>
  );
};

const StepTab = (props) => {
  const { activeStep, step } = props;

  if (activeStep != step) return null;

  return props.children;
};

const General = (props) => {
  const [file, setFile] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [methodName, setMethodName] = React.useState("");
  const snack = React.useContext(SnackContext);

  const { onSuccess = () => {} } = props;

  const mutation = useMutation(createMethod, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      snack.setSnack({
        title: "The method could not be created",
        severity: "error",
      });
    },
  });
  const handleChange = (file) => {
    let reader = new FileReader();

    setImage(URL.createObjectURL(file));
    setFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let method_name = e.target.method_name.value;

    mutation.mutate({ name: method_name, logo: file });
  };

  const isDisabled = () => {
    return !file || methodName == "";
  };
  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6">General</Typography>
      <TextField
        value={methodName}
        onChange={(e) => setMethodName(e.target.value)}
        label="Method name"
        fullWidth
        name="method_name"
      />
      <br />
      <br />
      <Box>
        <Typography variant="subtitle">Logo</Typography>
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={["PNG", "JPG", "JPEG"]}
          children={
            <Box
              sx={{ padding: 2, border: "3px dashed rgba(0,0,0,0.3)" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {image != null && (
                <img src={image} style={{ maxHeight: 100, maxWidth: 100 }} />
              )}
              <Image />
              <Typography variant="h6">Drop logo here</Typography>
            </Box>
          }
        />
      </Box>

      <br />
      <LoadingButton
        size="large"
        variant="contained"
        fullWidth
        type="submit"
        loading={mutation.isLoading}
        disabled={isDisabled()}
      >
        Next
      </LoadingButton>
    </Box>
  );
};

const MethodItem = () => {
  const [methodItemValid, setMethodItemValid] = React.useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    let method_item = e.target.method_item.value;
    console.log(method_item);
  };
  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h5">Method Item</Typography>
      <br />
      <TextField label="Method Item Name" value="DEFAULT" fullWidth disabled />
      <br />
      <br />
      <MethodItemForm name="method_item" setValid={setMethodItemValid} />
      <br />
      <Button
        variant="contained"
        type="submit"
        fullWidth
        disabled={!methodItemValid}
      >
        Submit
      </Button>
    </Box>
  );
};

const Countries = () => {
  return <div>Countries</div>;
};

export default AddMethodPage;
