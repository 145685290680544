import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Button } from "@mui/material";
import useApiFile from "../hooks/apiFile";
import MonacoEditor from "@uiw/react-monacoeditor";
import APIDocsPage from "./../APIDocsPage";
import { useQuery } from "@tanstack/react-query";
import { SnackContext } from "@providers";

const APIEditor = (props) => {
  const { file } = props;

  const snack = React.useContext(SnackContext);

  const [fileData, setFileData] = useState(null);

  const { data } = useQuery(["apiFile"], () => {
    return fetch(file).then((res) => res.json());
  });

  useEffect(() => {
    if (data) {
      setFileData(data);
    }
  }, [data]);

  const editorRef = React.useRef(null);

  const submit = () => {
    const value = editorRef.current.editor.getValue();
    //convert to json
    try {
      const json = JSON.parse(value);

      setFileData(json);
    } catch (e) {
      snack.setSnack({
        title: "Invalid JSON",
        severity: "error",
      });
    }
  };
  if (!fileData) {
    return null;
  }

  return (
    <Box
      style={{
        height: "calc(100vh - 64px)",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          height: "100%",
          overflowY: "hidden",
        }}
      >
        <Grid item xs={6} style={{ overflowY: "scroll", height: "100%" }}>
          <Box
            style={{
              height: "100%",
              overflowY: "scroll",
            }}
          >
            <APIDocsPage file={fileData} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" onClick={submit}>
            Submit
          </Button>
          <MonacoEditor
            ref={editorRef}
            height={800}
            width="100%"
            value={JSON.stringify(fileData, null, 4)}
            language="json"
            options={{
              theme: "vs-dark",
              automaticLayout: true,
              padding: {
                top: 10,
              },
              fontSize: 12,
              fontFamily: "Cereal",
              cursorBlinking: "blink",
              formatOnType: true,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default APIEditor;
