import { combineReducers } from "redux";
import tokenSlice from "./slices/token.slice";

const getAllReducersFromSlices = (slices) => {
  const reducers = {};
  Object.keys(slices).forEach((slice) => {
    reducers[slice] = slices[slice].reducer;
  });
  return reducers;
};

const getAllActionsFromSlices = (slices) => {
  const actions = {};
  Object.keys(slices).forEach((slice) => {
    actions[slice] = slices[slice].actions;
  });
  return actions;
};

export const rootActions = {
  token: tokenSlice.actions,
  logout: tokenSlice.actions.remove,
};

export default combineReducers({
  token: tokenSlice.reducer,
});
