import { MultiPlayerContext } from "../providers";
import { Avatar } from "./Avatar";
import { useContext } from "react";
import { useEffect } from "react";
import { useFrame } from "@react-three/fiber";
export const MultiPlayer = () => {
  const multiplayer = useContext(MultiPlayerContext);

  return (
    <>
      <group>
        {multiplayer.otherPlayers.map((player) => {
          if (player != undefined) {
            return (
              <Avatar
                key={player.id}
                avatar={player.avatar}
                position={player.position}
              />
            );
          }
        })}
      </group>
    </>
  );
};
