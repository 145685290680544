import RoomPage from "../pages/RoomPage";
import VideoChat from "../pages/RoomPage/VideoChat";
import DashboardPage from "../pages/DashboardPage";
const routes = [
  {
    path: "/",
    element: <DashboardPage />,
  },
  {
    path: "/room/:room_id",
    element: <RoomPage />,
  },
];

export default routes;
