import {
  Card,
  CardContent,
  TextField,
  Box,
  Container,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import PageLayout from "./../../layout/PageLayout";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUserData, updateUserData } from "@api/user";
import { useForm } from "@components/Form";
import { LoadingButton } from "@mui/lab";
import { SnackContext } from "@providers";
const ProfilePage = () => {
  const snack = React.useContext(SnackContext);
  const { data: user, isLoading, error } = useQuery(["user"], getUserData);

  const userMutation = useMutation(updateUserData, {
    onSuccess: (data) => {
      console.log(data);
      snack.success("Updated successfully");
    },
    onError: (data) => {
      console.log(data);
      snack.error("Something went wrong");
    },
  });
  const form = useForm(
    {
      first_name: "string",
      last_name: "string",
      email: "valid_email",
    },
    user
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    userMutation.mutate(form.form);
  };

  return (
    <PageLayout>
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <Typography variant="h3">
          {user?.first_name} {user?.last_name}
        </Typography>

        <Card variant="outlined" sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h5">Profile Settings</Typography>
            <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
              <TextField
                label="First Name"
                fullWidth
                {...form.getProps("first_name")}
              />
              <TextField
                label="Last Name"
                fullWidth
                sx={{ mt: 2 }}
                {...form.getProps("last_name")}
              />
              <TextField
                label="Email"
                fullWidth
                sx={{ mt: 2 }}
                {...form.getProps("email")}
              />
              <LoadingButton
                loading={userMutation.isLoading}
                sx={{ mt: 2 }}
                variant="contained"
                type="submit"
                disabled={!form.isValid.all}
              >
                Submit
              </LoadingButton>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </PageLayout>
  );
};

export default ProfilePage;
