import "./style/index.scss";

import store, { actions } from "@store";
import { Grid, Container, Typography } from "@mui/material";
import PageLayout from "../_shared/layout/PageLayout";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserData } from "@api/user";
import { PlatformList } from "@features/platforms";
import { useNavigate } from "react-router-dom";
import { jwtData } from "@utils/jwt";
const DashboardPage = () => {
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: user } = useQuery(["user"], getUserData);

  return (
    <PageLayout>
      <Container maxWidth="lg">
        <Typography variant="h5" m={2}>
          Welcome {user?.first_name}
        </Typography>

        <PlatformList
          onPlatformClick={(platform) => {
            navigate(`/platform/${platform.platform_name}`);
          }}
        />
      </Container>
    </PageLayout>
  );
};

export default DashboardPage;
