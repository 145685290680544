import {
  Email,
  Sms,
  Home,
  Description,
  Storage,
  Terminal,
} from "@mui/icons-material";

import communication from "@features/platforms/features/Communication/routes/SideBarItems";
import payout from "@features/platforms/features/Payout/routes/SideBarItems";
import survey from "@features/platforms/features/Survey/routes/SideBarItems";
const sideBarItems = (platform) => {
  let items = Object.keys(sidebarItemsPlatforms).includes(platform)
    ? sidebarItemsPlatforms[platform]?.segments
    : [];
  return [
    {
      type: "link",
      name: "dashboard",
      icon: <Home />,
      title: "Dashboard",
      link: `/platform/${platform}`,
    },
    ...items,
    {
      title: "System",
      name: "system",
      type: "segment",
      children: [
        {
          type: "link",
          name: "documentation",
          title: "API Documentation",
          link: "/platform/" + platform + "/documentation",
          icon: <Description />,
        },
        {
          type: "link",
          name: "db",
          title: "Database Management",
          link: "/platform/" + platform + "/db",
          icon: <Storage />,
        },
      ],
    },
  ];
};

const sidebarItemsPlatforms = {
  communication,
  payout,
  survey,
};

export default sideBarItems;
