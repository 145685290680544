import { Container, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";

const DashboardPage = (props) => {
  return <Navigate to="room/1" />;
  return (
    <Container>
      <Typography variant="h1">Conference</Typography>
    </Container>
  );
};

export default DashboardPage;
