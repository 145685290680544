import { useEffect, useState } from "react";

const useRecorder = () => {
  const [recording, setRecording] = useState(false);
  //stream is the audio stream from the microphone
  const [stream, setStream] = useState(null);
  //audioChunks is an array that holds the audio data in small chunks
  const [audioChunks, setAudioChunks] = useState([]);
  //audioURL is the URL of the audio file that is created after the recording is stopped
  const [audioURL, setAudioURL] = useState(null);
  //audioBlob is the blob of the audio file that is created after the recording is stopped
  const [audioBlob, setAudioBlob] = useState(null);
  //audio is the audio file that is created after the recording is stopped
  const [audio, setAudio] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  //startRecording starts the recording of the audio
  const startRecording = (callback = () => {}) => {
    //requesting access to the microphone
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        //setting the stream to the state
        setStream(stream);
        //creating a MediaRecorder object
        const mediaRecorder = new MediaRecorder(stream);
        //setting the state of mediaRecorder to the state
        setMediaRecorder(mediaRecorder);

        mediaRecorder.start(2000);

        mediaRecorder.addEventListener("dataavailable", (event) => {
          console.log(event);
          setAudioChunks((prev) => {
            if (prev.length == 0) {
              return [event.data];
            } else {
              return [prev[0], event.data];
            }
          });
        });

        setRecording(true);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (recording) {
      console.log("recording");
    }
  }, [audioChunks]);
  //stopRecording stops the recording of the audio
  const stopRecording = () => {
    //stopping the recording
    mediaRecorder.stop();
    //setting the recording state to false
    setRecording(false);
    //closing the stream
    stream.getTracks().forEach((track) => track.stop());
    //creating a blob of the audio file
    const blob = new Blob(audioChunks);
    //setting the blob to the state
    setAudioBlob(blob);
    //creating a URL of the audio file
    const url = URL.createObjectURL(blob);
    //setting the URL to the state
    setAudioURL(url);
    //creating an audio file
    const audio = new Audio(url);
    //setting the audio file to the state
    setAudio(audio);
    //play the audio file
    audio.play();
  };

  return {
    recording,
    startRecording,
    stopRecording,
    audioURL,
    audioBlob,
    audio,
    audioChunks,
  };
};

export default useRecorder;
