const notfound = () => {
  return (
    <div>
      <center>
        <h1>404</h1>
        <p>Page not found</p>
      </center>
    </div>
  );
};

export default notfound;
