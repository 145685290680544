import { createRoot } from "react-dom/client";
import React, { useRef, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Physics } from "@react-three/cannon";

import {
  KeyboardControls,
  OrbitControls,
  PerspectiveCamera,
  Stars,
  Sky,
  Cloud,
} from "@react-three/drei";

import { Ground } from "./components/Ground";
import { Person } from "./components/Person";
import { FPV } from "./components/FPV";
import { Cube } from "./components/Cube";
import { Sphere } from "./components/Sphere";
import { MultiPlayer } from "./components/MultiPlayer";
import { Model } from "./components/Model";
import { PlayerWindowModel } from "./components/PlayerWindowModel";
import { Terrain } from "./components/Terrain";

import ServerProvider from "./providers/ServerProvider";
import { useSocket } from "./hooks/useSocket";
import { useEffect, createContext, useContext } from "react";
import { Socket } from "./hooks/useSocket";
import { MultiPlayerContext } from "./providers";
import { useSelector } from "react-redux";

import AudioProvider from "./providers/AudioProvider";
import { jwtData } from "@utils/jwt";

import VideoChat from "./components/VideoChat";
import { Vector3 } from "three";
import { stringToColor } from "@utils/stringToColor";
const App = () => {
  const { socket } = useContext(Socket);
  const multiplayer = useContext(MultiPlayerContext);
  const token = useSelector((state) => state.token);
  const user_data = jwtData(token);
  useEffect(() => {
    socket.on("connect", () => {
      multiplayer.join();
    });
    console.log("connect");

    socket.on("joined", multiplayer.joined);
    socket.on("movement", multiplayer.playerMovement);
    socket.on("leave", multiplayer.playerLeave);
    socket.on("playerJoined", multiplayer.addPlayer);
    return () => {
      socket.off("joined", multiplayer.joined);
      socket.off("movement", multiplayer.playerMovement);
      socket.off("leave", multiplayer.playerLeave);
      socket.off("playerJoined", multiplayer.addPlayer);
    };
  }, []);

  return (
    <>
      <Canvas style={{ height: "100vh" }}>
        <Sky sunPosition={[4, 5, 1]} />
        <ambientLight intensity={0.5} />
        <FPV />
        <Physics>
          <Person />
          <MultiPlayer />

          <Terrain />
        </Physics>
      </Canvas>
      <VideoChat />

      <div style={{ position: "absolute", top: 20, left: 20 }}>
        {multiplayer.otherPlayers.map((player, index) => {
          return (
            <PlayerWindow
              key={player.id + "esf"}
              data={player}
              size={0.7}
              position={{ top: 0, left: 100 * index }}
            />
          );
        })}
      </div>
      <PlayerWindow data={user_data} me={true} />
    </>
  );
};

const PlayerWindow = (props) => {
  const {
    data,
    position = { bottom: 50, left: 50 },
    size = 1,
    scale = 10,
    me = false,
  } = props;
  const userData = data.token ? jwtData(data.token) : data;
  const color = stringToColor(userData.first_name + " " + userData.last_name);
  return (
    <div
      style={{
        position: "absolute",
        ...position,
        height: 100 * size,
        width: 100 * size,
        backgroundColor: color,
        borderRadius: 50,
      }}
    >
      <Canvas
        style={{
          height: 100 * size * 0.8,
          width: 100 * size * 0.8,
          margin: "auto",
        }}
      >
        <ambientLight intensity={0.5} />
        <group scale={scale} position={new Vector3(0, -16, 0)} key={data.id}>
          <Model avatar={userData.avatar} key={data.id + "deesf"} />
        </group>
      </Canvas>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          textAlign: "center",
          marginTop: 30 * size,
        }}
      >
        {!me && (
          <h6 style={{ margin: 0 }}>
            {userData.first_name} {userData.last_name}
          </h6>
        )}
      </div>
    </div>
  );
};

const Metaverse = () => {
  return (
    <ServerProvider>
      <App />
    </ServerProvider>
  );
};

export default Metaverse;
