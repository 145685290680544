import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { useEffect } from "react";

const MethodItemsSelect = (props) => {
  const {
    methodItems,
    value = null,
    onChange = () => {},
    label = "Select Method Item",
    name = "method_item_id",
    isDefaultInitial = true,
  } = props;

  const defaultItem = methodItems.find((item) => item.item_name === "DEFAULT");

  useEffect(() => {
    if (defaultItem && isDefaultInitial && value == null) {
      onChange(defaultItem?.method_item_id);
    }
  }, []);

  if (methodItems.length == 0) {
    return null;
  }
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value == null ? defaultItem?.method_item_id : value}
        label={label}
        name={name}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {methodItems.map((item) => {
          return (
            <MenuItem value={item.method_item_id}>{item.item_name}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MethodItemsSelect;
