import React from "react";
import { useSphere } from "@react-three/cannon";
import { useThree, useFrame } from "@react-three/fiber";
import { useKeyboardControls } from "../hooks/useKeyboardControls";
import { Vector3 } from "three";
import { useRef } from "react";
import { useEffect, useContext } from "react";
import { MultiPlayerContext } from "../providers";
import { PerspectiveCamera } from "@react-three/drei";
import { Model } from "./Model";
const SPEED = 4;
const JUMP_FORCE = 5;

export const Person = (props) => {
  const multiplayer = useContext(MultiPlayerContext);

  const { camera } = useThree();
  const movement = useKeyboardControls();

  const [ref, api] = useSphere(() => ({
    mass: 1,
    type: "Dynamic",
    position: [0, 0, 0],
  }));
  useEffect(() => {
    camera.rotation.order = "YXZ";
  }, []);

  const vel = useRef([0, 0, 0]);
  useEffect(() => {
    api.velocity.subscribe((v) => (vel.current = v));
  }, [api.velocity]);

  const pos = useRef([0, 0, 0]);
  useEffect(() => {
    api.position.subscribe((p) => (pos.current = p));
  }, [api.position]);

  useFrame((state) => {
    ref.current.getWorldPosition(camera.position);
    camera.position.copy(new Vector3(...pos.current));

    let sideVector = new Vector3(
      ...[(movement.moveLeft ? -1 : 0) + (movement.moveRight ? 1 : 0), 0, 0]
    );

    let forwardVector = new Vector3(
      ...[
        0,
        0,
        (movement.moveForward ? -1 : 0) + (movement.moveBackward ? 1 : 0),
      ]
    );

    let direction = new Vector3();
    direction.addVectors(sideVector, forwardVector);
    direction.normalize();
    direction.multiplyScalar(SPEED);
    direction.applyEuler(camera.rotation);

    api.velocity.set(direction.x, vel.current[1], direction.z);
    if (movement.jump && Math.abs(vel.current[1]) < 0.1) {
      api.velocity.set(vel.current[0], JUMP_FORCE, vel.current[2]);
    }
    multiplayer.setMovement(
      [camera.rotation.x, camera.rotation.y, camera.rotation.z],
      {
        x: pos.current[0],
        y: pos.current[1],
        z: pos.current[2],
      }
    );
  });
  if (!multiplayer.player?.avatar) {
    return null;
  }
  return (
    <>
      <mesh ref={ref} />
    </>
  );
};
