import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import rootReducer, { rootActions } from "./rootReducer";

const persistConfig = {
  key: "root",
  whitelist: ["token"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [thunk],
});
export const persistor = persistStore(store);
export const actions = { ...rootActions };

export default store;
