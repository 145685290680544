import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { MoveDown } from "@mui/icons-material";

import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Button,
  Box,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  getProfilersDataPerCountry,
  getProfilers,
  getProfiler,
} from "../../../api";

import PageLayout from "@features/platforms/layout/PageLayout";
const Dashboard = (props) => {
  const { profiler_id, country } = useParams();

  const { data, isLoading, error } = useQuery(
    ["profilers", country, profiler_id],
    () => getProfiler({ profiler_id, country }),
    {
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Container maxWidth="xl">
      <h3>{data?.question}</h3>
    </Container>
  );
};

export default Dashboard;
