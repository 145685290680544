import { useMutation } from "@tanstack/react-query";
import * as api from "../api";
import store, { actions } from "@store";
import tokenSlice from "@store/slices/token.slice";

const useLogin = (params) => {
  const { redirect = () => {} } = params;

  const mutation = useMutation(api.login, {
    onSuccess: (data) => {
      store.dispatch(tokenSlice.actions.set(data.token));
      redirect();
    },
    onError: (data) => {
      console.log(data);
    },
  });

  return { mutation };
};

export default useLogin;
