import { Container, Box, Grid, Button, ButtonGroup } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";

import { useSelector } from "react-redux";
import { jwtData } from "@utils/jwt";
import { useParams } from "react-router-dom";

import React, { useRef, useEffect, useState, useContext, useMemo } from "react";

import Peer from "simple-peer";
//import io from "socket.io-client";
import { io } from "socket.io-client/dist/socket.io.js";
import "core-js/stable";

import "./index.css";

const videoConstraints = {
  height: 1000,
  width: 1200,
};

const useRoom = (room_id) => {
  const token = useSelector((state) => state.token);
  const user_data = jwtData(token);
  const [peers, setPeers] = useState([]);
  const socketRef = useRef();

  const peersRef = useRef([]);
  const userVideo = useRef();

  //camera,screen,none
  const [mediaState, setMediaState] = useState("camera");
  const [cameraStream, setCameraStream] = useState(null);

  var displayMediaOptions = {
    video: {
      cursor: "always",
      height: 1000,
      width: 1200,
    },
    audio: true,
  };

  var cameraMediaOptions = { video: videoConstraints, audio: true };

  var noneMediaOptions = { video: { height: 0, width: 0 }, audio: true };

  const updateMediaStream = async () => {
    // Request and set the new stream based on mediaState
    const newStream = await (async () => {
      switch (mediaState) {
        case "camera":
          return navigator.mediaDevices.getUserMedia(cameraMediaOptions);
        case "screen":
          return navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
        case "none":
          return navigator.mediaDevices.getUserMedia(noneMediaOptions);
        default:
          throw new Error("Invalid media state");
      }
    })();

    // Stop the previous tracks
    if (userVideo.current.srcObject) {
      userVideo.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
    }

    // Replace the stream in userVideo
    userVideo.current.srcObject = newStream;

    // Replace the stream in all peers
    const replaceTrackPromises = peersRef.current.map(async (p) => {
      const senders = p.peer._pc.getSenders();
      const newTracks = newStream.getTracks();

      for (const sender of senders) {
        const newTrack = newTracks.find(
          (track) => track.kind === sender.track.kind
        );
        if (newTrack) {
          await sender.replaceTrack(newTrack);
        }
      }
    });

    await Promise.all(replaceTrackPromises);
  };

  useEffect(() => {
    if (mediaState) {
      updateMediaStream();
    }
  }, [mediaState]);

  useEffect(() => {
    socketRef.current = io.connect("https://api.master.yflow.io:12051");

    navigator.mediaDevices
      .getUserMedia(cameraMediaOptions)
      .then((stream) => insideMediaFunction(stream));
  }, []);

  const insideMediaFunction = (stream) => {
    setCameraStream(stream);
    userVideo.current.srcObject = stream;
    socketRef.current.emit("join room", {
      room_id,
      token,
    });

    socketRef.current.on("all users", (users) => {
      console.log(users);
      const peers = [];

      users.forEach((userToken) => {
        const peer = createPeer(userToken, token, stream);
        peersRef.current.push({
          peerID: userToken,
          peer,
        });
        peers.push({ peer, token: userToken });
      });
      setPeers(peers);
    });

    socketRef.current.on("user joined", (payload) => {
      console.log(payload);

      const peer = addPeer(payload.signal, payload.callerID, stream);
      peersRef.current.push({
        peerID: payload.callerID,
        peer,
      });

      setPeers((users) => [...users, { peer, token: payload.callerID }]);
    });

    socketRef.current.on("receiving returned signal", (payload) => {
      const item = peersRef.current.find((p) => p.peerID === payload.id);
      item.peer.signal(payload.signal);
    });

    socketRef.current.on("user left", (userToken) => {
      console.log(userToken);
      const peerObj = peersRef.current.find((p) => p.peerID === userToken);

      const peers = peersRef.current.filter((p) => p.peerID !== userToken);
      peersRef.current = peers;
      setPeers((users) => users.filter((user) => user.token !== userToken));
    });
  };
  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("sending signal", {
        userToSignal,
        callerID,
        signal,
      });
    });

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("returning signal", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  return {
    peers,
    peersRef,
    userVideo,
    socketRef,
    mediaState,
    updateMediaStream,
    setMediaState,
  };
};

const Video = (props) => {
  const { peer } = props;
  const ref = useRef();
  useEffect(() => {
    peer.on("stream", (stream) => {
      ref.current.srcObject = stream;
    });
  }, []);

  return <video className="peer-video" playsInline autoPlay ref={ref} />;
};

const RoomPage = () => {
  const { room_id } = useParams();

  const token = useSelector((state) => state.token);
  const user_data = jwtData(token);

  const room = useRoom(room_id);

  const columns = useMemo(() => {
    return Math.ceil(Math.sqrt(room.peers.length + 1));
  }, [room.peers]);

  const handleMediaStateChange = (newMediaState) => {
    room.setMediaState(newMediaState);
    room.updateMediaStream();
  };

  return (
    <>
      <div
        className="media-controls"
        style={{
          position: "fixed",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1000,
        }}
      >
        <ButtonGroup variant="contained">
          <Button
            color={room.mediaState === "camera" ? "primary" : "secondary"}
            onClick={() => handleMediaStateChange("camera")}
          >
            <VideocamIcon />
            Camera
          </Button>
          <Button
            color={room.mediaState === "screen" ? "primary" : "secondary"}
            onClick={() => handleMediaStateChange("screen")}
          >
            <ScreenShareIcon />
            Screen
          </Button>
          <Button
            color={room.mediaState === "none" ? "primary" : "secondary"}
            onClick={() => handleMediaStateChange("none")}
          >
            <VideocamOffIcon />
            None
          </Button>
        </ButtonGroup>
      </div>
      <div className="video-grid-container">
        {room.peers.map(({ peer, token }, index) => {
          const data = jwtData(token);
          return (
            <div className="video-container">
              <Video peer={peer} />
            </div>
          );
        })}
      </div>
      <div className="my-video-container">
        {room.mediaState !== "none" && (
          <video muted ref={room.userVideo} autoPlay playsInline />
        )}
      </div>
    </>
  );
};

export default RoomPage;
